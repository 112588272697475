// src/components/PaginatedTable.tsx

import React, { useState } from 'react';
import {formateDate} from "../utils/dateutils";
interface Data {
  id: number;
  name: string;
  createdAt: string;
}

interface PaginatedTableProps {
  data: Data[];
  page: number;
  setPage: (value: any) => void;
  rpp: number;
  setRPP: (value: any) => void;
  rppoption: number[];
  
}

const Table: React.FC<PaginatedTableProps> = ({ data,page,setPage,rpp,setRPP, rppoption }) => {

  const handleChangePage = (newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setRPP(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <div>
      {data.length ?
       <div className="pagination">
        <button
          onClick={() => handleChangePage(page - 1)}
          disabled={page === 0}
        >
          <i className="bx bx-skip-previous"></i>
        </button>
        <span>{`Page ${page + 1} of ${Math.ceil(data.length / rpp)}`}</span>
        <button
          onClick={() => handleChangePage(page + 1)}
          disabled={page >= Math.ceil(data.length / rpp) - 1}
        >
           <i className="bx bx-skip-next"></i>
        </button>
        <label>
          Rows per page:
          <select value={rpp} onChange={handleChangeRowsPerPage}>
            {rppoption.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
        </label>
      </div>
      : null
        
     }
     
    </div>
  );
};

export default Table;
