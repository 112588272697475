import { APIClient } from "./apiCore";
import * as url from "./urls";

const api = new APIClient();

const getTemplates = (filters?: object) => {
  return api.get(url.GET_TEMPLATES, filters);
};

const sendTemplates = ( value: any) => {
  return api.create(url.SEND_TO_USER_TEMPLATES, {
    value: value,
  });
};

const bulkTemplates = ( value: any) => {
  return api.create(url.SEND_BULK_TEMPLATE, value);
};
const createTemplates = ( value: any) => {
  return api.create(url.CREATE_BULK_TEMPLATE, value);
};
export { getTemplates,sendTemplates, bulkTemplates, createTemplates };
