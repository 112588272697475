import React, { useEffect, useState } from 'react'
import { createSelector } from "reselect";
import Importing from './Importing';
import Select from 'react-select';
// hooks  
import { useRedux } from "../../../../hooks/index";
import Table from '../../../../components/Table';
import {
  Form,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  Input,
} from "reactstrap";

interface DataTypes {
  name: string | null;
}
const Index = () => {
    const { dispatch, useAppSelector } = useRedux();
    const [page, setPage] = useState(0);
    let rowsPerPageOptions = [10, 15, 20];
    const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[0]);


    
   const errorData = createSelector(
    (state: any) => state.Contacts,
    (aud: any) => aud.Audience,
    (state, aud) => ({
      contactsList: state.contacts,
      getContactsLoading: state.getContactsLoading,
      isContactInvited: state.isContactInvited,
      audience: aud.audience,
    })
    );

  // Inside your component
    const { contactsList, audience, getContactsLoading, isContactInvited } = useAppSelector(errorData);
    const [selectedContactList, SetSelectedContactList] = useState([]);
    const idsAndNames = audience.map((data: any, key: number) => ({ value: data.id, label: data.name }));
    const [selectedAudience, SetSelectedAudience] = useState({ value: "", label: "" });
  const [isCreating, SetisCreating] = useState(false);

    useEffect(() => {
        if (selectedContactList && selectedAudience) {
            let new_contacts = contactsList.filter((e: any) => { return e.audience_id == selectedAudience.value });
            console.log("selected audience", selectedAudience, new_contacts, selectedContactList);
            SetSelectedContactList(new_contacts);
        }
    }, [selectedAudience]);

    useEffect(() => {
        SetSelectedContactList(contactsList);
    }, [contactsList]);
  const [data, setData] = useState<DataTypes>({
    name: null,
  });
  const [isImporting, SetisImporting] = useState(false);
  const onChangeData = (field: "name", value: string) => {
    let modifiedData: DataTypes = { ...data };
    if (value === "") {
      modifiedData[field] = null;
    } else {
      modifiedData[field] = value;
    }
    setData(modifiedData);
    console.log("contact data", data)
  };
  return (
    <div className='cloud_container'>
      <div className="cloud_middle">
           <h4>Contacts</h4>
              <>
                
                      <Importing  isImporting={isImporting} audience={audience} SetisImporting={SetisImporting} />
               


                  <div className="d-flex justify-content-between py-2">
                      <div>
                           <Select
                                value={selectedAudience}
                                onChange={(e: any) => {
                                    SetSelectedAudience(e)
                                    }}
                                options={idsAndNames}
                                placeholder="Select Audience"
                            />
                      </div>
                      <div>
                          <button className="btn btn-secondary" onClick={e=>SetisImporting(true)}>Import</button>
                    </div>
                </div>
                      <div className='content'>
                      {selectedContactList.length ?
                      <>
                          {selectedContactList.length} Records
                         <table>
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Phon Number</th>
                            </tr>
                            </thead>
                         <tbody>
                            {selectedContactList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row:any) => (
                                <tr key={row.id}>
                                    <td>{row.name}</td>
                                    <td>{row.wa_id}</td>
                                </tr>
                            ))}
                        </tbody>
                              </table>
                              </>
                          :
                          <div className='text-center'>
                          Record Not Found      
                        </div>
                        
                          
                       }   
                 


                <Table data={selectedContactList} page={page} setPage={setPage} rpp={rowsPerPage} setRPP={setRowsPerPage} rppoption={rowsPerPageOptions} />
            </div>
            
            </>
     
        
      </div>
    </div>
  )
}

export default Index
