import { LayoutActionTypes } from "./types";

export const changeTab = (layoutMode: any) => ({
  type: LayoutActionTypes.CHANGE_TAB,
  payload: layoutMode,
});

export const changelayoutMode = (layoutMode :any) => ({
  type: LayoutActionTypes.CHANGE_LAYOUT_MODE,
  payload: { layoutMode},
});

export const changeCloudTab = (layoutMode :any) => ({
  type: LayoutActionTypes.CHANGE_CLOUD_TAB_MODE,
  payload: { layoutMode},
});