import { takeEvery, fork, put, all, call } from "redux-saga/effects";

//  Redux States
import { GallaryActionTypes } from "./types";
import {
  mediaApiResponseSuccess,
  mediaApiResponseError,
} from "./actions";

// api
import {
  getMedia as getMediaApi,

} from "../../api/index";

// helpers
import {
  showSuccessNotification,
  showErrorNotification,
} from "../../helpers/notifications";

function* getGallary({ payload: filters }: any) {
    try {
    const response: Promise<any> = yield call(getMediaApi, filters);
    yield put(
      mediaApiResponseSuccess(GallaryActionTypes.GET_GALLARY, response)
    );
  } catch (error: any) {
    yield put(
      mediaApiResponseError(GallaryActionTypes.GET_GALLARY, error)
    );
  }
}

export function* watchGetGallary() {
  yield takeEvery(GallaryActionTypes.GET_GALLARY, getGallary);
}


function* gallarySaga() {
  yield all([fork(watchGetGallary)]);
}

export default gallarySaga;
