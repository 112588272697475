// types
import { GallaryActionTypes, GallaryState } from "./types";

export const INIT_STATE:GallaryState = {
  gallary: [],
};

const Gallary = (state = INIT_STATE, action: any) => {
  switch (action.type) {
    case GallaryActionTypes.API_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case GallaryActionTypes.GET_GALLARY:
          return {
            ...state,
            gallary: action.payload.data.data,
            isGallaryFetched: true,
            getGallaryLoading: false,
          };
       default:
          return { ...state };
      }

    case GallaryActionTypes.API_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case GallaryActionTypes.GET_GALLARY:
          return {
            ...state,
            isGallaryFetched: false,
            getGallaryLoading: false,
          };
        default:
          return { ...state };
      }

    case GallaryActionTypes.GET_GALLARY: {
      return {
        ...state,
        getGallaryLoading: true,
        isGallaryFetched: false,
      };
    }

    default:
      return { ...state };
  }
};

export default Gallary;
