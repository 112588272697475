import React from "react";
import { Navigate } from "react-router-dom";
import { createSelector } from "reselect";
import { useProfile } from "../hooks/index";
import { useRedux } from "../hooks/index";
const AuthProtected = (props: any) => {
  const { userProfile, loading } = useProfile();
 const errorData = createSelector(
    (state: any) => state.Login,
    (state) => ({
      session: state.session,
    })
  );
  const { useAppSelector } = useRedux();
   const { session } = useAppSelector(errorData);
  /*
    redirect is un-auth access protected routes via url
  */
  // if (!session) {
  //   return (
  //     <Navigate to={{ pathname: "/auth-login"}} />
  //   );
  // } 
  
  if (!userProfile && loading) {
    return (
      <Navigate to={{ pathname: "/auth-login"}} />
    );
  }

  return <>{props.children}</>;
};

export { AuthProtected };
