import { AudienceActionTypes } from "./types";
// common success
export const audienceApiResponseSuccess = (actionType: string, data: any) => ({
  type: AudienceActionTypes.API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});
// common error
export const audienceApiResponseError = (
  actionType: string,
  error: string
) => ({
  type: AudienceActionTypes.API_RESPONSE_ERROR,
  payload: { actionType, error },
});
export const getAudience = (filters?: object) => ({
  type: AudienceActionTypes.GET_AUDIENCE,
  payload: filters,
});


export const sendAudience = ( value: any) => ({
  type: AudienceActionTypes.SEND_AUDIENCE,
  payload:  value ,
});
