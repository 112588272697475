export enum LAYOUT_MODES {
  LIGHT = "light",
  DARK = "dark",
}

export enum CHATS_TABS {
  ARCHIVE = "acrhive",
  DEFAULT = "default",
}

export enum TABS {
  USERS = "pills-user",
  CHAT = "pills-chat",
  CONTACTS = "pills-contacts",
  MANAGE = "pills-calls",
  BOOKMARK = "pills-bookmark",
  SETTINGS = "pills-setting",

}
export enum CLOUD_TABS{
  TEMPLATE = "pills-template",
  IMPORT = "pills-import",
  GROUP = "pills-group"
}
