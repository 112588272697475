import { format } from "date-fns";

const formateDate = (date: any, formate?: string) => {
  const dateObj = new Date(date);
  const dateFormat = formate ? formate : "MM/dd/yyyy";
  const formattedDate = format(dateObj, dateFormat);
  return formattedDate;
};
// export const formatDate = (isoDate: string): string => {
//   const date = new Date(isoDate);
//   const options: Intl.DateTimeFormatOptions = { day: '2-digit', month: 'short', year: 'numeric' };
//   return new Intl.DateTimeFormat('en-GB', options).format(date);
// };
export { formateDate };
