import React, { useEffect, useState } from "react";

// hooks
import { useRedux } from "../../../hooks/index";
import { createSelector } from "reselect";
import { Nav,NavItem,NavLink, UncontrolledTooltip } from "reactstrap";
import { CLOUD_MENUS, CloudMenuItemType } from "../../../layouts/Default/menu";
import { changeTab , changeCloudTab} from "../../../redux/actions";
// components
import Loader from "../../../components/Loader";
import AppSimpleBar from "../../../components/AppSimpleBar";
import LeftbarTitle from "../../../components/LeftbarTitle";
import { CLOUD_TABS } from "../../../constants";
// actions
import { getCalls } from "../../../redux/actions";

// interface
import { CallItem } from "../../../data/calls";


interface MenuNavItemProps {
  
  item: CloudMenuItemType;
  selectedTab:
    | CLOUD_TABS.TEMPLATE
    | CLOUD_TABS.IMPORT
    | CLOUD_TABS.GROUP
  onChangeTab: (
    id:
      | CLOUD_TABS.TEMPLATE
      | CLOUD_TABS.IMPORT
      | CLOUD_TABS.GROUP
  ) => void;
}
const MenuNavItem = ({ item, selectedTab, onChangeTab }: MenuNavItemProps) => {
  const onClick = () => {
    onChangeTab(item.tabId);
  };
  return (
    <>
      <NavItem className={item.className} id={`${item.key}-container`}>
        <NavLink
          href="#"
          active={selectedTab === item.tabId}
          id={item.key}
          role="tab"
          onClick={onClick}
        >
          {item.tooltipTitle}
        </NavLink>
      </NavItem>
      <UncontrolledTooltip target={`${item.key}-container`} placement="right">
        {item.tooltipTitle}
      </UncontrolledTooltip>
    </>
  );
};


interface IndexProps {}
const Index = (props: IndexProps) => {
  const menuItems: CloudMenuItemType[] = CLOUD_MENUS;
  // global store
  const { dispatch, useAppSelector } = useRedux();

  // const { calls, getCallsLoading } = useAppSelector((state: any) => ({
  //   calls: state.Calls.calls,
  //   getCallsLoading: state.Calls.getCallsLoading,
  // }));
  const [selectedTab, setSelectedTab] = useState<
    | CLOUD_TABS.TEMPLATE
    | CLOUD_TABS.IMPORT
    |CLOUD_TABS.GROUP
    >(CLOUD_TABS.IMPORT);
  
  const onChangeTab = (
    id:
    | CLOUD_TABS.TEMPLATE
      | CLOUD_TABS.IMPORT
    |CLOUD_TABS.GROUP
  ) => {
    setSelectedTab(id);
    dispatch(changeCloudTab(id));
  };
  const errorData = createSelector(
    (state : any) => state.Calls,
    (state) => ({
      calls: state.calls,
      getCallsLoading: state.getCallsLoading,
    })
  );
  // Inside your component
  const { calls,getCallsLoading} = useAppSelector(errorData);



  // get user calls
  useEffect(() => {
    dispatch(getCalls());
  }, [dispatch]);

  const [callsList, setCallsList] = useState([]);

  useEffect(() => {
    setCallsList(calls);
  }, [calls]);

  return (
    <div className="position-relative">
      {getCallsLoading && <Loader />}
      <LeftbarTitle title="Manage" />
      {/* end p-4 */}

      {/* Start contact lists */}
      <AppSimpleBar className="chat-message-list chat-call-list">
         <Nav pills className="top-menu-nav" role="tablist">
          {(menuItems || []).map((item: CloudMenuItemType, key: number) => (
            <MenuNavItem
              item={item}
              key={key}
              selectedTab={selectedTab}
              onChangeTab={onChangeTab}
            />
          ))}

          
            </Nav>
      </AppSimpleBar>
      {/* end contact lists */}
    </div>
  );
};

export default Index;
