export enum TemplatesActionTypes {
  API_RESPONSE_SUCCESS = "@@Templates/API_RESPONSE_SUCCESS",
  API_RESPONSE_ERROR = "@@Templates/API_RESPONSE_ERROR",

  GET_TEMPLATES = "@@Templates/GET_Templates",
  SEND_TEMPLATES = "@@Templates/SEND_TEMPLATES",
  BULK_TEMPLATES = "@@Templates/BULK_TEMPLATES",
  CREATE_TEMPLATES = "@@Templates/CREATE_TEMPLATES",
}
export interface TemplatesState {
  templates: Array<any>;
}
