// types
import { TemplatesActionTypes, TemplatesState } from "./types";

export const INIT_STATE:TemplatesState = {
  templates: [],
};

const Templates = (state = INIT_STATE, action: any) => {
  switch (action.type) {
    case TemplatesActionTypes.API_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case TemplatesActionTypes.GET_TEMPLATES:
          return {
            ...state,
            templates: action.payload.data.data,
            isTemplatesFetched: true,
            getTemplatesLoading: false,
          };
        case TemplatesActionTypes.SEND_TEMPLATES:
          return {
            ...state,
            isTemplateUpdated: true,
          };
        case TemplatesActionTypes.CREATE_TEMPLATES:
          return {
            ...state,
            isTemplateCreated: true,
          };
        case TemplatesActionTypes.BULK_TEMPLATES:
          return {
            ...state,
            isBulkTemplateUpdated: true,
          };
        default:
          return { ...state };
      }

    case TemplatesActionTypes.API_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case TemplatesActionTypes.GET_TEMPLATES:
          return {
            ...state,
            isTemplatesFetched: false,
            getTemplatesLoading: false,
          };
          case TemplatesActionTypes.SEND_TEMPLATES:
          return {
            ...state,
            isTemplateUpdated: false,
          };
          case TemplatesActionTypes.CREATE_TEMPLATES:
          return {
            ...state,
            isTemplateCreated: false,
          };
            case TemplatesActionTypes.BULK_TEMPLATES:
          return {
            ...state,
            isBulkTemplateUpdated: false,
          };
        default:
          return { ...state };
      }

    case TemplatesActionTypes.GET_TEMPLATES: {
      return {
        ...state,
        getTemplatesLoading: true,
        isTemplatesFetched: false,
      };
    }
    case TemplatesActionTypes.SEND_TEMPLATES:
      return {
        ...state,
        isTemplateUpdated: false,
      };
    case TemplatesActionTypes.CREATE_TEMPLATES:
      return {
        ...state,
        isTemplateCreated: false,
      };
    case TemplatesActionTypes.BULK_TEMPLATES:
      return {
        ...state,
        isBulkTemplateUpdated: false,
      };
    default:
      return { ...state };
  }
};

export default Templates;
