import React, { useContext, useEffect, useState } from "react";
// import "./myStyles.css";
// import logo from "../Images/live-chat_512px.png";
import { useDispatch, useSelector } from "react-redux";

import axios from "axios";
import { useNavigate } from "react-router-dom";
// import { refreshSidebarFun } from "../Features/refreshSidebar";
import { myContext } from "./MainContainer";
interface settingsProps {
  settings: any;
  onUpdateSettings: (field: string, value: any) => void;
}
function Setting({ settings, onUpdateSettings }: settingsProps) {
  const { refresh, setRefresh } = useContext(myContext);
  const [settingValue, setSettingValue] = useState({
    business_id: "",
    phon_number_id: "",
    recipient_phon_number: "",
    user_access_token: "",
    waba_id: "",
    version: "",
  });

  const shuffle_setting_values = (response: any) => {
    setSettingValue((prevState: any) => {
      return response.reduce(
        (updatedState: any, item: any) => {
          if (item.name === "business_id") {
            updatedState.business_id = item.value;
          }
          if (item.name === "phon_number_id") {
            updatedState.phon_number_id = item.value;
          }
          if (item.name === "recipient_phon_number") {
            updatedState.recipient_phon_number = item.value;
          }
          if (item.name === "user_access_token") {
            updatedState.user_access_token = item.value;
          }
          if (item.name === "waba_id") {
            updatedState.waba_id = item.value;
          }
          if (item.name === "version") {
            updatedState.version = item.value;
          }
          return updatedState;
        },
        { ...prevState }
      );
    });
  };

  useEffect(() => {
    // let data = [
    //   {
    //     createdAt: "2024-04-08T13:29:41.000Z",
    //     id: 1,
    //     name: "business_id",
    //     updatedAt:"2024-05-03T12:38:03.000Z",
    //     value:"1166574318874",
    //   },
    //   {
    //     createdAt:"2024-04-09T05:19:22.000Z",
    //     id: 2,
    //     name: "phon_number_id",
    //     updatedAt:"2024-04-09T06:42:49.000Z",
    //     value:"236038546267099",
    //   },
    //   {
    //     createdAt:"2024-04-09T05:19:33.000Z",
    //     id: 3,
    //     name: "recipient_phon_number",
    //     updatedAt:"2024-04-09T05:19:33.000Z",
    //     value:"918955624054",
    //   },
    // ];
    if (settings) {
       shuffle_setting_values(settings);
    }   

  }, [settings]);

  const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name;
    const value = e.target.value;
    setSettingValue({ ...settingValue, [name]: value });
  };

  const handleSave = async (name: string) => {
    try {
      let nameval = name;
      let value = settingValue[name as keyof typeof settingValue];
      onUpdateSettings(nameval, value)
    } catch (error) {
      console.log("error", error);
    }
  };

  const lightTheme = useSelector((state: any) => state.themeKey);
  const [users, setUsers] = useState<any[]>([]);
  const userDataString = localStorage.getItem("userData");
  const userData = userDataString ? JSON.parse(userDataString) : null;

  const nav = useNavigate();
  const dispatch = useDispatch();
  // if (!userData) {
  //   console.log("User not Authenticated");
  //   nav(-1);
  // }

  return (
<div className="list-container">
      

        <div className="ug-list">
          <label>
            Business Id:
          </label>

          <div className="bussness-id-input" >
            <input
              placeholder="Enter Business Id"
              value={settingValue.business_id}
              onChange={handleInput}
              name="business_id"
            />
            <i className="bx bx-check"  onClick={(e) => handleSave("business_id")}></i>
          </div>

          <label >
            Phon Number Id:
          </label>
          <div className="bussness-id-input">
            <input
              placeholder="Enter Phon Number Id"           
              value={settingValue.phon_number_id}
              onChange={handleInput}
              name="phon_number_id"
            />
             <i className="bx bx-check"  onClick={(e) => handleSave("phon_number_id")}></i>
          </div>
          <label className="recipient">
            Recipient Number Id:
          </label>
          <div className="bussness-id-input">
            <input
              placeholder="Enter Recipient Phon Number"
             
              value={settingValue.recipient_phon_number}
              onChange={handleInput}
              name="recipient_phon_number"
            />
           
             
              <i className="bx bx-check"  onClick={(e) => handleSave("recipient_phon_number")}></i>
            
          </div>
          <label >
            User Access Token:
          </label>
          <div className="bussness-id-input">
            <input
              placeholder="Enter User Access Token"
              
              value={settingValue.user_access_token}
              onChange={handleInput}
              name="user_access_token"
            />
          <i className="bx bx-check" onClick={(e) => handleSave("user_access_token")}></i>
          </div>
          <label>
            Waba Id:
          </label>
          <div className="bussness-id-input">
            <input
              placeholder="Enter Waba Id"
              
              value={settingValue.waba_id}
              onChange={handleInput}
              name="waba_id"
            />
            <i className="bx bx-check" onClick={(e) => handleSave("waba_id")}></i>
          </div>
          <label>
            Version:
          </label>
          <div className="bussness-id-input">
            <input
              placeholder="Enter Version"
             
              value={settingValue.version}
              onChange={handleInput}
              name="version"
            />
             <i className="bx bx-check" onClick={(e) => handleSave("version")}></i>
          </div>
        </div>

</div>
  );
}

export default Setting;
