import React from 'react'
const Index = ({ templateValue }: any) => {
  const splitAndTagString =(inputString:string)=> {
    const emClassRegex = /_([^_]+)_/g; // regex to match text enclosed within underscores
    const mainClassRegex = /\*([^\*]+)\*/g; // regex to match text enclosed within asterisks

    let result = inputString.replace(emClassRegex, '<em class="emclass">$1</em>'); 
    result = result.replace(mainClassRegex, '<span class="mainclass">$1</span>'); 

    return result;
  }
  return (
    <div>
       {templateValue ?
          <div className='templateprevcontainer'>
            <span className='left-curve'>
     
             </span>
              <div className='templatePreview'>
          
                <div>
                  {templateValue.components?.map((item:any, index:number) => {
                    if (item.type=="HEADER") {
                        return <div className='header'>
                          {item.format == "IMAGE" ?
                            templateValue.show_image ?
                              <img className='cloud_header_image shwo image' src={templateValue.show_image} />
                              :<img className='cloud_header_image not show' src={item.example.header_handle[0]} />
                                
                                : null}
                            {item.text}</div>
                    }
                    if (item.type=="BODY") {
                    return   <div className='body'>
                       <div dangerouslySetInnerHTML={{ __html: splitAndTagString(item.text) }} />
                   </div>
                    }
                     if (item.type=="FOOTER") {
                    return  <div className='footer'>{item.text}</div>              
                    }
                    
                  })}
               
                </div>
            </div>
             
          
                
                  {templateValue.components?.map((item:any, index:number) => {
                    if (item.type == "BUTTONS") {
                     return   <div className='templatePreview bt'>
                      <div className='buttons'>{
                        item.buttons?.map((bItem:any, Bi:number) => {
                          if (bItem.type=="PHONE_NUMBER") {
                            return <div className='phon_number'><a>{ bItem.text}</a></div>
                          }
                         })
                      }
                        </div>
                      </div>              
                    }
                  })}
               
               
          
            </div>
             :null
          }
    </div>
  )
}

export default Index
