import { GallaryActionTypes } from "./types";

export const mediaApiResponseSuccess = (actionType: string, data: any) => ({
  type: GallaryActionTypes.API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});

export const mediaApiResponseError = (
  actionType: string,
  error: string
) => ({
  type: GallaryActionTypes.API_RESPONSE_ERROR,
  payload: { actionType, error },
});

export const getGallary = (filters?: object) => ({
  type: GallaryActionTypes.GET_GALLARY,
  payload: filters,
});


