import React, { useEffect, useState } from "react";
import config from "../../../../config";
import sampleImage from "../../../../assets/images/icons/image-gallery.png";
import samplevideo from "../../../../assets/images/icons/video.png";
import sampledoc from "../../../../assets/images/icons/document.png";
import { cloud_lang } from "../../../../data/cloud_lang";
import { Form, Label, Input } from "reactstrap";
import { useRedux } from "../../../../hooks";
import { APIClient } from "../../../../api/apiCore";
import { createTemplates } from "../../../../redux/actions";
import { isEmptyBindingElement } from "typescript";
interface CreateTypes{
    onBack : (value:any) => void;
}
interface CallToAction {
  type: string;
  title: string;
  code: string;
  btn_value: string;
 [key: string]: any;
}
interface QuickReplyType{
  btn_title: string;
   [key: string]: any;
}
interface TempVarType{
  index: number;
  content: any;
}
const Create = ({ onBack }: CreateTypes) => {
  const api = new APIClient();
  const { dispatch, useAppSelector } = useRedux();
  const [mediaUrl, SetMediaUrl] = useState("");
    const emptyTempInfo = {
        catagory: "",
        temp_name: "",
        media_type: "",
        temp_format: "Welcome and congratulations!! This message demonstrates your ability to send a WhatsApp message notification. Thank you for taking the time to test with us.",
        footer: "",
        lang: "",
        action:""
  }
  let speciel_character = {
    left: "{{",
    right:"}}",
  }
    const [allowedActions, SetAllowedActions] = useState({
        allowed_cta: 2,
        allowed_quick_reply: 3,
        allowed_phon_number: 1,
        allowed_url:1
    });
    const [actionType, SetActionType] = useState("none");
    const [tempVariable, SetTempVariable] = useState<TempVarType[]>([]);
  const [templateInfo, SetTemplateInfo] = useState(emptyTempInfo);
  const updateState = (e:any) => {
    const name = e.target.name;
    let value = e.target.value; 
    if (name == "temp_format") {
      set_variable(value);
    } else {
      SetTemplateInfo({ ...templateInfo, [name]: value });
    }
    
  };
   const set_variable = (value: string) => {
      
    // Extract values inside {{}}
    const regex = /\{\{(\d+)\}\}/g;
     const matches = Array.from(value.matchAll(regex)).map(match => parseInt(match[1]));


    // Generate a sequence with equal gaps
    const uniqueMatches = Array.from(new Set(matches)); // Remove duplicates
    uniqueMatches.sort((a, b) => a - b); // Sort in ascending order
    const processedIndexes = uniqueMatches.map((_, index) => index + 1);

    // Create a copy of processedIndexes for replacement
    const indexesForReplacement = [...processedIndexes];

    // Update the textarea with the new sequence
    const newInputValue = value.replace(regex, () => {
      const index = indexesForReplacement.shift();
      return `{{${index}}}`;
    });
     SetTemplateInfo({ ...templateInfo, temp_format: newInputValue });
    SetTempVariable(processedIndexes.map(index => ({ index, content:"" })));
   }
  const convert_temp_body =(inputString:string)=> {
    const emClassRegex = /_([^_]+)_/g; // regex to match text enclosed within underscores
    const mainClassRegex = /\*([^\*]+)\*/g; // regex to match text enclosed within asterisks
    const strikethroughRegex = /~([^~]+)~/g; 
    const numberRegex = /{{(\d+)}}/g;

    let result = inputString.replace(emClassRegex, '<em class="emclass">$1</em>'); 
    result = result.replace(mainClassRegex, '<span class="mainclass">$1</span>'); 
    result = result.replace(strikethroughRegex, '<span class="strikethrough">$1</span>');
    result = result.replace(numberRegex, (match, number) => {
      const newValue = (tempVariable.find((e)=>e.index==number))?.content;
        return newValue ? `[${newValue}]` : match;
    });



    return result;
    }
    const format_message = "Use text formatting - *bold* , _italic_ & ~ strikethrough~Your message content. Upto 1024 characters are allowed. e.g. - Hello {{1}}, your code will expire in {{2}} mins."
  const handleActionType = (val: any) => {
      let value = val.target.value; 
      SetQuickReplies([]);
      SetCallToActions([]);
       if (value=="all") {
        SetAllowedActions({ ...allowedActions,allowed_quick_reply:10, allowed_phon_number:1, allowed_url:2 })
      }
      SetActionType(value);
  }
  
  const [call_to_actions, SetCallToActions] = useState<CallToAction[]>([]);
  const [quickReplies, SetQuickReplies] = useState<QuickReplyType[]>([]);
  const updateQuickReply = (e: any) => {
   let index = parseInt(e.target.getAttribute('index'), 10);
  let field = e.target.getAttribute('field');
  let value = e.target.value;
  let updatedReplies = [...quickReplies];

  if (updatedReplies[index]) {
    updatedReplies[index] = {
      ...updatedReplies[index],
      [field]: value
    };
  } else {
    updatedReplies.push({
      btn_title: field === "btn_title" ? value : ""
    });
  }
  SetQuickReplies(updatedReplies);
}
const updateOptions = (e: any) => {
  let index = parseInt(e.target.getAttribute('index'), 10);
  let field = e.target.getAttribute('field');
  let value = e.target.value;
  let updatedActions = [...call_to_actions];
  let existingIndex = updatedActions.findIndex(action => action.index === index);

  if (updatedActions[index]) {
    updatedActions[index] = {
      ...updatedActions[index],
      [field]: value
    };
  } else {
    updatedActions.push({
      type: field === "type" ? value : "",
      title: field === "title" ? value : "",
      code: field === "code" ? value : "",
      btn_value: field === "btn_value" ? value : ""
    });
  }
  SetCallToActions(updatedActions);
  };
const getQrByIndex = (field:any, index:number) => {
  return quickReplies[index]?quickReplies[index][field]:"";
  };
  const uploadMedia = async(e:any) => {
     let formData = new FormData();
     formData.append('file', e.target.files[0]);
    let res = await api.uploadFile("/api/upload", formData);
    if (res.data) {
      SetMediaUrl(res.data.url);
    }
    console.log("response", res.data.url);
  }
const getCtaByIndex = (field:any, index:number) => {
  const action = call_to_actions.find(action => action.index === index);
  // return action ? action[field] : "";
  return call_to_actions[index]?call_to_actions[index][field]:"";
  };
  const find_cta_options = (index: number) => {
    let indexSet = [0, 1, 2, 3, 4];
    let default_values = [{ label: "URL", value: "url" }, { label: "Phon Number", value: "phon_number" }];
    let new_values;
    let checkSet = indexSet.filter(item => item !== index);
    checkSet.forEach((item, ind) => {
      let value = call_to_actions[item];
      if (value) {
        new_values = default_values.filter(item => item.value !== value.type);
      }
    });

    return new_values?new_values: default_values;
  }
  const removeCta = (index:number) => {
    SetCallToActions(prevActions => prevActions.filter((_, i) => i !== index));
  }
  const removeQr = (index:number) => {
     SetQuickReplies(prevActions => prevActions.filter((_, i) => i !== index)); 
  }
  const createQr = () => {
    if ((quickReplies.length)<allowedActions.allowed_quick_reply) {
        let updatedQr = [...quickReplies];
          updatedQr.push({
            btn_title: ""
          });
        SetQuickReplies(updatedQr);
    }
  }
  const createCta = (allowed_type: string, number: number) => {
  
    let total_count; 
    if (allowed_type=="phon_number") {
       total_count = (call_to_actions.filter(e => e.type == "phon_number")).length;
    } else if (allowed_type=="url") {
      total_count =  (call_to_actions.filter(e => e.type == "url")).length;
    } else {
      total_count = call_to_actions.length; 
    }
      console.log("url", allowed_type, call_to_actions, total_count, number);
      if (total_count<number) {
        let updatedActions = [...call_to_actions];
          updatedActions.push({
            type: allowed_type,
            title: "",
            code:  "",
            btn_value : ""
          });
          SetCallToActions(updatedActions);
      }
  }
  const updateVarible = (e:any) => {
    let value = e.target.value;
    let index = e.target.getAttribute('index');
    index = parseInt(index) + 1;
    console.log("testing", value, index, tempVariable);
    SetTempVariable(prevState => 
      prevState.map(item => 
        item.index == index ? { ...item, content: value } : item
      )
    );
  }
  const SendTemplate = (e:any) => {
    e.preventDefault();
    let template = { name: templateInfo.temp_name,
                    category: templateInfo.catagory,
                    allow_category_change: true,
                    language: templateInfo.lang};
    let components: any[] = [];
    if (templateInfo.temp_format) {
      let temp_text = { "type": "BODY", "text": templateInfo.temp_format }
      let body_type;
      let button_type;

      if (templateInfo.media_type !="TEXT" ) {
       let data =  {
          type:"HEADER",
         format: templateInfo.media_type,
         example:{header_handle:{id:"811125530954248"}}
          // example:{header_handle:"https://digitalsunbird.com/cloud_api_work/images/website_offers.png"}
          // example:{header_handle:"config.API_URL+mediaUrl"}
              }
         components.push(data)
      }
      if (tempVariable.length > 0) {
        let temp_vriable_values: any[]=[];
          tempVariable.forEach(item => {
            temp_vriable_values[item.index-1] = item.content;
          });
          body_type = {...temp_text, example:{
            body_text: [temp_vriable_values]
          }
        }
        components.push(body_type);
      } else {
        components.push(temp_text);
      }
      if (call_to_actions.length>0) {
        let call_action_wrapper: any[] = [];
        call_to_actions.forEach(item => {
          if (item.type == "phon_number") {
            call_action_wrapper.push({
              type: "PHONE_NUMBER", text: item.title, phone_number: "+91" + item.btn_value
            })
          }
          if (item.type == "url") {
            call_action_wrapper.push({
              type: "URL", text: item.title, url: "+91" + item.btn_value
            })
          }
        });
        button_type = { type: "BUTTONS", buttons: call_action_wrapper }
        components.push(button_type);
      }
      let temp_with_comp = {...template, components}
      console.log("template", temp_with_comp);
       dispatch(createTemplates(temp_with_comp));
    } else {
      console.log("temp format empty");
    }
    console.log("send template");
    console.log("template Info", templateInfo);
    console.log("template variable", tempVariable);
    console.log("call to action", call_to_actions);
    console.log("quickReplies", quickReplies);
    
       
  }
  return (
    <div>
          <div className='d-flex align-items-center'>
            <div onClick={onBack}><i className='bx bx-arrow-back'></i></div>
            <span className='mx-2'>Back</span>
          </div>
          <div className='content'>
           <Form onSubmit={SendTemplate}>
         <div className="row">
                         <div className="col-6">
                               <div className="mb-3">
                                    <Label htmlFor="AddContactModalname-input" className="form-label">
                                         Template Catagory
                              </Label>
                         
                                    <Input type="select" name="catagory" id="exampleSelect" value={templateInfo.catagory} onChange={updateState}>
                                        <option value="">Select an option...</option>
                                        <option value="utility">UTILITY</option>
                                        <option value="marketing">MARKETING</option>
                                        <option value="uthentication">AUTHENTICATION</option>
                                    </Input>
                                </div>
                                <div className="mb-3">
                                    <Label htmlFor="AddContactModalname-input" className="form-label">
                                    Template Name
                                    </Label>
                                    <Input
                                    type="text"
                                    className="form-control"
                                    id="AddContactModalname-input"
                                    placeholder="Enter Template Name"
                                    name="temp_name"
                                    value={templateInfo.temp_name}
                                    onChange={updateState}
                                    />
                                </div>
                                
                                <div className="mb-3">
                                    <Label htmlFor="AddContactModalname-input" className="form-label">
                                         Template Type
                                    </Label>
                                    <Input type="select" name="media_type" id="exampleSelect" value={templateInfo.media_type}  onChange={updateState}>
                                        <option value="">Select an option...</option>
                                        <option value="TEXT">Text</option>
                                        <option value="IMAGE">Image</option>
                                        <option value="VIDEO">Video</option>
                                    </Input>
                                </div>
              {(templateInfo.media_type == "IMAGE")?
                 mediaUrl ?
                  <div className="mb-3">
                    <div className="dac">
                       <div>
                    <img height={40} src={config.API_URL+mediaUrl} alt=""  />
                    </div>  
                    <div className="btn btn-secondary" onClick={e=>SetMediaUrl("")}>
                      remove
                    </div>
                    </div>
                      
                </div> 
              :  <div className="mb-3">
                            <input type="file" onChange={uploadMedia} />
                  </div>
              
              : null
              }
                         
             
             
                            <div className="mb-3">
                                    <Label htmlFor="AddContactModalname-input" className="form-label">
                                    Template Format 
                              </Label>
                                <div>{ format_message}</div>
                                    <Input
                                    type="textarea"
                                    className="form-control"
                                    name="temp_format"
                                    id="AddContactModalname-input"
                                    placeholder="Enter Template Format"
                                    value={templateInfo.temp_format}
                                    onChange={updateState}
                                    />
              </div>
              {tempVariable ?
                <div className="mb-3">
                                    <Label htmlFor="AddContactModalname-input" className="form-label">
                                    Sample Values
                              </Label>
               
                              { tempVariable.map((item,vari) => {
                                  return <>
                                    <div className="dac mt-2">
                                      <div className="mx-2">{speciel_character.left}{item.index}{speciel_character.right}</div>
                                      <div>
                                        <Input
                                          type="text"
                                          className="form-control"
                                          name="temp_format"
                                          index={vari}
                                          id="AddContactModalname-input"
                                          placeholder="Enter Template Format"
                                          value={tempVariable[vari].content}
                                          onChange={updateVarible}
                                          />
                                      </div>
                                    </div>
                                  </>
                              })}
                              </div>
                              : null}
                             
                             <div className="mb-3">
                                    <Label htmlFor="AddContactModalname-input" className="form-label">
                                    Template Footer
                              </Label>
                                    <Input
                                    type="text"
                                    className="form-control"
                                    name="footer"
                                    id="AddContactModalname-input"
                                    placeholder="Enter Footer Text Here"
                                    value={templateInfo.footer}
                                    onChange={updateState}
                                    />
                          </div>
                          <div className="mb-3">
                                    <Label htmlFor="AddContactModalname-input" className="form-label">
                                         Template Language
                                    </Label>
                                    <Input type="select" name="lang" id="exampleSelect"  value={templateInfo.lang} onChange={updateState}>
                                  <option value="">Select an Language...</option>
                                  {cloud_lang.map((item:any, i:number) => {
                                      return  <option key={i} value={item.code} >{item.language}</option>
                                     })    
                                  }
                                    </Input>
                                </div>
                    
                        </div>
                      <div className="col-6">
                        Template Preview
                      
                        <div className="d-flex justify-content-center align-items-center">
                            <div className="w-50">
                              <div className="templateprevcontainer">
                                  <span className="left-curve"></span>
                                  <div className="templatePreview">
                                      <div>
                                            
                                                <div className="header">
                                                 {templateInfo.media_type == "IMAGE" ?
                                                  mediaUrl ?
                                                    <div className="djcac">
                                                      <img height={40} src={mediaUrl} alt="" className="centerImg" />
                                                    </div>
                                                    : <div className="media_container"><img src={sampleImage} alt="" className="centerImg" /></div>
                                                  : null
                                                }{templateInfo.media_type == "VIDEO" ?
                                                    <div className="media_container"><img src={samplevideo} alt="" className="centerImg" /></div>: null
                                                }{templateInfo.media_type == "DOC" ?
                                                    <div className="media_container"><img src={sampledoc} alt="" className="centerImg" /></div>: null
                                                } 
                                            
                                                
                                                
                                              </div>
                                          <div className="body">
                                                <div>
                                                    <div dangerouslySetInnerHTML={{ __html: convert_temp_body(templateInfo.temp_format)}} />
                                          </div>
                                          </div>
                                          <div className="footer">{templateInfo.footer}</div>
                                      </div>
                                  </div>
                                  <div className="templatePreview bt">
                                      <div className="buttons">
                                          <div className="phon_number"><a>Call Now</a></div>
                                      </div>
                                  </div>
                              </div>
                              </div>
                        </div>
                    </div>
          </div>
          <div className="row">
                  <div className="mb-3">
                                    <Label htmlFor="AddContactModalname-input" className="form-label">
                                            Interactive Actions
                              </Label>
                              <div className="radio_buttons d-flex">
                                  <div>
                                      <Input
                                        type="radio"
                                        value="none"
                                        checked={actionType==="none"}
                                        onChange={handleActionType}
                                      />
                                      None
                                  </div>
                                  <div>
                                      <Input
                                        type="radio"
                                        value="cta"
                                        checked={actionType==="cta"}
                                        onChange={handleActionType}
                                      />
                                      Call To Actions
                                  </div>
                                  <div>
                                      <Input
                                        type="radio"
                                          value="qr"
                                        checked={actionType==="qr"}
                                        onChange={handleActionType}
                                      />
                                      Quick Replies
                                  </div>
                                  <div>
                                      <Input
                                        type="radio"
                                         value="all"
                                        checked={actionType==="all"}
                                        onChange={handleActionType}
                                      />
                                      All

                                  </div>
                                    
                              </div>
                                  
                          </div>
                           <div className="mb-3">
               
              {actionType == "cta" ?
              
                <>
                     {
                         [...Array(call_to_actions.length)].map((item:any, i:number) => {
                        return <div className="dac mt-2 gp-1">
                              <div>Call to action { i+1}</div>
                              <div>
                                <Input type="select" name="media_type" id="exampleSelect" field="type" index={i} value={getCtaByIndex('type',i)} onChange={updateOptions}>
                                  <option value="" disabled selected hidden>Select an option</option>
                                  {find_cta_options(i).map((item:any,i:number) => {
                                    return <option value={item.value}>{item.label}</option>
                                  })}      
                                </Input>
                              </div>
                              <div>
                                  <Input
                                  type="text"
                                  className="form-control"
                                  placeholder="Button Title"
                                  name="temp_name"
                                  field="title"
                                  index={i}
                                  value={getCtaByIndex('title',i)}
                                  onChange={updateOptions}
                                  />
                             </div>
                       <div>
                         {getCtaByIndex('type', i) == "phon_number" ?
                                  <Input type="select" name="media_type" id="exampleSelect" field="code" index={i} value={getCtaByIndex('code',i)} onChange={updateOptions}>
                                    <option value="" disabled selected hidden>Select an option</option>      
                                </Input>
                         : null
                           
                         }

                         </div>
                             <div>
                                  <Input
                                  type="text"
                                  className="form-control"
                                  placeholder="Button Value"
                                  name="temp_name"
                                  field="btn_value"
                                  index={i}
                                  value={getCtaByIndex('btn_value',i)}
                                  onChange={updateOptions}
                                  />
                       </div>
                             <div>
                               <div onClick={e=>removeCta(i)}><i className="bx bx-x"></i></div> 
                              </div>
                             </div>;        
                          })

                              }
                             <div>
                              <div onClick={e=>createCta("",allowedActions.allowed_cta)} className="btn btn-outline-secondary">
                                + call to action
                              </div>
                             </div>
                     </>
                
                                  :null
                                    }
                    {actionType == "qr" ?
                      <>
                        {
                              [...Array(quickReplies.length)].map((item:any, i:number) => {
                          return <div className="dac mt-2 gp-1">
                              <div>Quick Reply { i+1}</div>
                              <div>
                                  <Input
                                  type="text"
                                  className="form-control"
                                  placeholder="Button Title"
                                  name="temp_name"
                                  field="btn_title"
                                  index={i}
                                  value={getQrByIndex('btn_title',i)}
                                  onChange={updateQuickReply }
                                  />
                              </div>
                       
                             <div>
                               <div onClick={e=>removeQr(i)}><i className="bx bx-x"></i></div> 
                              </div>
                             </div>;        
                          })

                              }
                             <div>
                              <div onClick={createQr} className="btn btn-outline-secondary">
                                + Quick Reply
                              </div>
                             </div>
                          </>
                                
                                  :null
                              }
                              {actionType=="all"?
                            <>
                             {
                              [...Array(call_to_actions.length)].map((item:any, i:number) => {
                              return <div className="dac mt-2 gp-1">
                                  <div>Call to action { i+1}</div>
                                <div>
                                   <Input
                                      type="text"
                                      className="form-control"
                                      placeholder="Button Title"
                                      name="temp_name"
                                      field="type"
                                      index={i}
                                      readOnly
                                      value={getCtaByIndex('type',i)}
                                      onChange={updateOptions}
                                      />
                                   
                                  </div>
                                  <div>
                                      <Input
                                      type="text"
                                      className="form-control"
                                      placeholder="Button Title"
                                      name="temp_name"
                                      field="title"
                                      index={i}
                                      value={getCtaByIndex('title',i)}
                                      onChange={updateOptions}
                                      />
                                </div>
                          <div>
                            {getCtaByIndex('type', i) == "phon_number" ?
                                      <Input type="select" name="media_type" id="exampleSelect" field="code" index={i} value={getCtaByIndex('code',i)} onChange={updateOptions}>
                                        <option value="" disabled selected hidden>Select an option</option>      
                                    </Input>
                            : null
                              
                            }

                            </div>
                                <div>
                                      <Input
                                      type="text"
                                      className="form-control"
                                      placeholder="Button Value"
                                      name="temp_name"
                                      field="btn_value"
                                      index={i}
                                      value={getCtaByIndex('btn_value',i)}
                                      onChange={updateOptions}
                                      />
                          </div>
                                <div>
                                  <div onClick={e=>removeCta(i)}><i className="bx bx-x"></i></div> 
                                  </div>
                                </div>;        
                              })
                             }
                  
                             {
                              [...Array(quickReplies.length)].map((item:any, i:number) => {
                          return <div className="dac mt-2 gp-1">
                              <div>Quick Reply { i+1}</div>
                              <div>
                                  <Input
                                  type="text"
                                  className="form-control"
                                  placeholder="Button Title"
                                  name="temp_name"
                                  field="btn_title"
                                  index={i}
                                  value={getQrByIndex('btn_title',i)}
                                  onChange={updateQuickReply }
                                  />
                              </div>
                       
                             <div>
                               <div onClick={e=>removeQr(i)}><i className="bx bx-x"></i></div> 
                              </div>
                             </div>;        
                             })
                              }
                             <div className="dac gp-1 mt-2">
                                <div onClick={createQr} className="btn btn-outline-secondary">
                                  + Quick Reply
                                </div>
                                <div onClick={e=>createCta("url",allowedActions.allowed_url)} className="btn btn-outline-secondary">
                                  + URL
                                </div>
                                <div onClick={e=>createCta("phon_number",allowedActions.allowed_phon_number)} className="btn btn-outline-secondary">
                                  + Phon Number
                              </div>
                             </div>
                          </>
                                  :null
                              }
                        </div>
          </div>
          <div>
            <button type="submit" className="btn btn-primary">
              Submit
            </button>
          </div>
                  </Form>  
                     
                  
        </div>
          
    </div>
  )
}

export default Create
