// types
import { AudienceActionTypes, AudienceState } from "./types";

export const INIT_STATE:AudienceState = {
  audience: [],
};

const Audience = (state = INIT_STATE, action: any) => {
  switch (action.type) {
    case AudienceActionTypes.API_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case AudienceActionTypes.GET_AUDIENCE:
          return {
            ...state,
            audience: action.payload.data,
            isAudienceFetched: true,
            getAudienceLoading: false,
          };
        case AudienceActionTypes.SEND_AUDIENCE:
          return {
            ...state,
            isAudienceUpdated: true,
          };
        default:
          return { ...state };
      }

    case AudienceActionTypes.API_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case AudienceActionTypes.GET_AUDIENCE:
          return {
            ...state,
            isAudiencesFetched: false,
            getAudiencesLoading: false,
          };
          case AudienceActionTypes.SEND_AUDIENCE:
          return {
            ...state,
            isAudienceUpdated: false,
          };
        default:
          return { ...state };
      }

    case AudienceActionTypes.GET_AUDIENCE: {
      return {
        ...state,
        getAudiencesLoading: true,
        isAudiencesFetched: false,
      };
    }
    case AudienceActionTypes.SEND_AUDIENCE:
      return {
        ...state,
        isAudienceUpdated: false,
      };
    default:
      return { ...state };
  }
};

export default Audience;
