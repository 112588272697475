//auth
export const POST_FAKE_LOGIN = "/post-fake-login";
export const POST_COOKIES_LOGIN = "/api/auth/signin";
export const POST_FAKE_JWT_LOGIN = "/post-jwt-login";
export const POST_FAKE_PASSWORD_FORGET = "/fake-forget-pwd";
export const POST_FAKE_JWT_PASSWORD_FORGET = "/jwt-forget-pwd";
export const SOCIAL_LOGIN = "/social-login";
export const JWT_REGISTER = "/post-jwt-register";
export const POST_FAKE_REGISTER = "/post-fake-register";
export const USER_CHANGE_PASSWORD = "/user-change-password";

// session check
export const CHECK_SESSION = "/api/auth/check-session";
// session check
export const SESSION_REMOVE = "/api/auth/signout";
// profile & settings
export const GET_PROFILE_DETAILS = "/profile-details";
export const GET_USER_SETTINGS = "/user-settings";
export const UPDATE_ETTINGS = "/update-user-settings";

export const GET_API_SETTINGS = "/api/setting";
export const API_UPDATE_SETTINGS = "/api/setting";


// contacts
// export const GET_CONTACTS = "/user-contacts";
export const GET_CONTACTS = "/api/contacts";
export const INVITE_CONTACT = "/invite-contact";
export const GET_CONTACT_BY_AUD_ID = "/api/contacts_by_aud_id";

// templates
export const GET_TEMPLATES = "/api/templates";

// gallary
export const GET_GALLARY = "/api/get_images";


export const SEND_TO_USER_TEMPLATES = "/api/user/templates";
export const SEND_BULK_TEMPLATE = "/api/bulk_template";
export const CREATE_BULK_TEMPLATE = "/api/create/templates";
// audience
export const GET_AUDIENCE = "/api/audience";
export const SEND_TO_USER_AUDIENCE = "/api/audience";

// calls
export const GET_CALLS_LIST = "/calls-list";

// bookmarks
export const GET_BOOKMARKS_LIST = "/bookmarks-list";
export const DELETE_BOOKMARK = "/bookmarks-delete";
export const UPDATE_BOOKMARK = "/bookmarks-update";

// chats
export const GET_FAVOURITES = "/get-favourites";
// export const GET_DIRECT_MESSAGES = "/get-direct-messages";
export const GET_DIRECT_MESSAGES = "/api/user/fetchChats/";
export const GET_CHANNELS = "/get-channles";
export const ADD_CONTACTS = "/add-contact";
export const CREATE_CHANNEL = "/create-channel";
// export const GET_CHAT_USER_DETAILS = "/get-user-details";
export const GET_CHAT_USER_DETAILS = "/api/user";
// export const GET_CHAT_USER_CONVERSATIONS = "/get-user-conversations";
 export const GET_CHAT_USER_CONVERSATIONS = "/api/message";

// export const SEND_MESSAGE = "/send-message";
export const SEND_MESSAGE = "/api/message";
export const RECEIVE_MESSAGE = "/receive-message";
export const READ_MESSAGE = "/read-message";
export const RECEIVE_MESSAGE_FROM_USER = "/receive-message-from-user";
export const DELETE_MESSAGE = "/delete-message";
export const FORWARD_MESSAGE = "/forward-message";
export const DELETE_USER_MESSAGES = "/delete-user-messages";
export const TOGGLE_FAVOURITE_CONTACT = "/toggle-favourite-contact";
export const GET_ARCHIVE_CONTACT = "/get-archive-contacts";
export const TOGGLE_ARCHIVE_CONTACT = "/toggle-archive-contact";
export const READ_CONVERSATION = "/read-conversation";
export const DELETE_IMAGE = "/user-delete-img";



// groups
export const GET_CHANNEL_DETAILS = "/get-channel-details";
