import React from "react";
import classnames from "classnames";
import Cloud from "./Cloud/Index";
import Contacts from "./Cloud/Contacts/Index";
// hooks
import { useRedux } from "../../hooks/index";

// hooks
import { useConversationUserType } from "../../hooks/index";
import { createSelector } from "reselect";
// component
import Leftbar from "./Leftbar";
import ConversationUser from "./ConversationUser/index";
import UserProfileDetails from "./UserProfileDetails/index";
import Welcome from "./ConversationUser/Welcome";

interface IndexProps {}
const Index = (props: IndexProps) => {
  // global store
  const { useAppSelector } = useRedux();

  // const { selectedChat } = useAppSelector(state => ({
  //   selectedChat: state.Chats.selectedChat,
  // }));
  const errorData = createSelector(
    (state: any) => state.Chats,
    (lay : any) => lay.Layout,
    (state, lay) => ({
      selectedChat: state.selectedChat,
      activeTab: lay.activeTab,
      cloudTab: lay.cloudTab,
    })
  );
  const { selectedChat, activeTab, cloudTab} = useAppSelector(errorData);

  const { isChannel } = useConversationUserType();

  return (
    <>
   
      <Leftbar />

      <div
        className={classnames("user-chat", "w-100", "overflow-hidden", {
          "user-chat-show": selectedChat,
        }, {"cloud-show": cloudTab,})}
        id="user-chat"
      >
        {activeTab == "pills-contacts" ?
          <Contacts />
          :
          null
        }
        {activeTab == "pills-chat" ?
          <>
          {selectedChat !== null ? (
          <>
           <div className="user-chat-overlay" id="user-chat-overlay"></div>
             <div className="chat-content d-lg-flex">
              <div className="w-100 overflow-hidden position-relative">
              <ConversationUser isChannel={isChannel} />
            </div>
            <UserProfileDetails isChannel={isChannel} />
            </div>
          </>
        ) : (
            // <Cloud/>
          <Welcome />
        )}
          </>
          :
          null
        }
        {activeTab == "pills-calls" ?
          <Cloud/>
          :
          null
        }
       
        
      </div>
    </>
  );
};

export default Index;
