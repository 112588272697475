import { toast } from "react-toastify";

const showSuccessNotification = (message: any) => {
  if (message.success) {
  toast.success(message.message);  
  } else {
  toast.error(message.message);
  }
};

const showErrorNotification = (error: string) => {
  toast.error(error);
};

export { showSuccessNotification, showErrorNotification };
