import { takeEvery, fork, put, all, call } from "redux-saga/effects";

//  Redux States
import { AudienceActionTypes } from "./types";
import {
  audienceApiResponseSuccess,
  audienceApiResponseError,
} from "./actions";

// api
import {
  getAudience as getAudienceApi,
  sendAudience as sendAudienceApi
} from "../../api/index";

// helpers
import {
  showSuccessNotification,
  showErrorNotification,
} from "../../helpers/notifications";

function* getAudience({ payload: filters }: any) {
    try {
    const response: Promise<any> = yield call(getAudienceApi, filters);
    yield put(
      audienceApiResponseSuccess(AudienceActionTypes.GET_AUDIENCE, response)
    );
  } catch (error: any) {
    yield put(
      audienceApiResponseError(AudienceActionTypes.GET_AUDIENCE, error)
    );
  }
}
function* sendAudience({ payload:  value  }: any) {
  try {
    console.log("send template saga", value);
    const response: Promise<any> = yield call(sendAudienceApi, value);
    yield put(
      audienceApiResponseSuccess(
        AudienceActionTypes.SEND_AUDIENCE,
        response
      )
    );
  } catch (error: any) {
    yield put(
      audienceApiResponseError(AudienceActionTypes.SEND_AUDIENCE, error)
    );
  }
}

export function* watchGetAudiences() {
  yield takeEvery(AudienceActionTypes.GET_AUDIENCE, getAudience);
}

export function* watchUpdateAudience() {
  yield takeEvery(AudienceActionTypes.SEND_AUDIENCE, sendAudience);
}


function* audienceSaga() {
  yield all([fork(watchGetAudiences), fork(watchUpdateAudience)]);
}

export default audienceSaga;
