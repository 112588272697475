import React, { useEffect, useState } from "react";
import {
  Form,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  Input,
} from "reactstrap";

interface DataTypes {
  name: string | null;
  number: string | null;
  audience: string | null;
}
interface InviteContactModalProps {
  isOpen: boolean;
  audienceData: any;
  onClose: () => void;
  onInvite: (data: any) => void;
}
const InviteContactModal = ({
  isOpen,
  audienceData, 
  onClose,
  onInvite,
}: InviteContactModalProps) => {
  /*
  data input handeling
  */
  

  const [data, setData] = useState<DataTypes>({
    name: null,
    number: null,
    audience:null,
  });
  useEffect(() => {
    setData({
   name: null,
    number: null,
    audience:null,
    });
  }, []);

  const onChangeData = (field: "name" | "number" | "audience", value: string) => {
    let modifiedData: DataTypes = { ...data };
    if (value === "") {
      modifiedData[field] = null;
    } else {
      modifiedData[field] = value;
    }
    setData(modifiedData);
  };


  /*
  validation
  */
  // const [valid, setValid] = useState<boolean>(false);
  // useEffect(() => {
  //   if (data.email !== null && data.message !== null && data.name !== null) {
  //     setValid(true);
  //   } else {
  //     setValid(false);
  //   }
  // }, [data]);
  return (
    <Modal isOpen={isOpen} toggle={onClose} tabIndex={-1} centered scrollable >
      <ModalHeader  toggle={onClose} className="bg-primary">
      <div className="modal-title-custom text-white font-size-16 ">
      Create Contact
      </div>
      </ModalHeader>
      <ModalBody className="p-4">
        <Form>
      
          <div className="mb-3">
            <Label htmlFor="AddContactModalname-input" className="form-label">
              Name
            </Label>
            <Input
              type="text"
              className="form-control"
              id="AddContactModalname-input"
              placeholder="Enter Name"
              value={data["name"] || ""}
              onChange={(e: any) => {
                onChangeData("name", e.target.value);
              }}
            />
          </div>
             <div className="mb-3">
            <Label htmlFor="AddContactModalname-input" className="form-label">
              Number
            </Label>
            <Input
              type="number"
              className="form-control"
              id="AddContactModalname-input"
              placeholder="Enter number"
              value={data["number"] || ""}
              onChange={(e: any) => {
                onChangeData("number", e.target.value);
              }}
            />
          </div>
          <div className="mb-3">
            <Label htmlFor="AddContactModalname-input" className="form-label">
              Select Audience
            </Label>
             <Input type="select" name="select" id="exampleSelect" value={data["audience"] || ""} onChange={(e: any) => {
                onChangeData("audience", e.target.value);
              }}>
              <option value="">Select an option...</option>
              {(audienceData||[]).map((item:any, i:number) => {
                return <option key={i} value={item.id}>{ item.name}</option>
             })}
            </Input>
          </div>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button type="button" color="link" className="btn" onClick={onClose}>
          Close
        </Button>
        <Button
          type="button"
          color="primary"
          // disabled={!valid}
          onClick={() => onInvite(data)}
        >
          Invite
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default InviteContactModal;
