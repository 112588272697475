import { takeEvery, fork, put, all, call } from "redux-saga/effects";

//  Redux States
import { TemplatesActionTypes } from "./types";
import {
  templatesApiResponseSuccess,
  templatesApiResponseError,
} from "./actions";

// api
import {
  getTemplates as getTemplatesApi,
  sendTemplates as sendTemplatesApi,
  bulkTemplates as bulkTemplatesApi,
  createTemplates as createTemplatesApi
} from "../../api/index";

// helpers
import {
  showSuccessNotification,
  showErrorNotification,
} from "../../helpers/notifications";

function* getTemplates({ payload: filters }: any) {
    try {
    const response: Promise<any> = yield call(getTemplatesApi, filters);
    yield put(
      templatesApiResponseSuccess(TemplatesActionTypes.GET_TEMPLATES, response)
    );
  } catch (error: any) {
    yield put(
      templatesApiResponseError(TemplatesActionTypes.GET_TEMPLATES, error)
    );
  }
}
function* sendTemplates({ payload:  value  }: any) {
  try {
    console.log("send template saga", value);
    const response: Promise<any> = yield call(sendTemplatesApi, value);
    yield put(
      templatesApiResponseSuccess(
        TemplatesActionTypes.SEND_TEMPLATES,
        response
      )
    );
  } catch (error: any) {
    yield put(
      templatesApiResponseError(TemplatesActionTypes.SEND_TEMPLATES, error)
    );
  }
}

function* createTemplates({ payload:  value  }: any) {
  try {
    const response: Promise<any> = yield call(createTemplatesApi, value);
    yield put(
      templatesApiResponseSuccess(
        TemplatesActionTypes.CREATE_TEMPLATES,
        response
      )
    );
  } catch (error: any) {
    yield put(
      templatesApiResponseError(TemplatesActionTypes.CREATE_TEMPLATES, error)
    );
  }
}

function* bulkTemplates({ payload:  value  }: any) {
  try {
    const response: Promise<any> = yield call(bulkTemplatesApi, value);
    yield put(
      templatesApiResponseSuccess(
        TemplatesActionTypes.BULK_TEMPLATES,
        response
      )
    );
  } catch (error: any) {
    yield put(
      templatesApiResponseError(TemplatesActionTypes.BULK_TEMPLATES, error)
    );
  }
}

export function* watchGetTemplates() {
  yield takeEvery(TemplatesActionTypes.GET_TEMPLATES, getTemplates);
}

export function* watchUpdateSettings() {
  yield takeEvery(TemplatesActionTypes.SEND_TEMPLATES, sendTemplates);
}
export function* watchBulkSettings() {
  yield takeEvery(TemplatesActionTypes.BULK_TEMPLATES, bulkTemplates);
}
export function* watchCreateSettings() {
  yield takeEvery(TemplatesActionTypes.CREATE_TEMPLATES, createTemplates);
}


function* templatesSaga() {
  yield all([fork(watchGetTemplates), fork(watchUpdateSettings), fork(watchBulkSettings) , fork(watchCreateSettings)]);
}

export default templatesSaga;
