// constants
import { TABS , CLOUD_TABS, LAYOUT_MODES} from "../../constants/index";

export enum LayoutActionTypes {
  CHANGE_TAB = "@@layout/CHANGE_TAB",
  CHANGE_LAYOUT_MODE = "@@layout/CHANGE_LAYOUT_MODE",
  CHANGE_CLOUD_TAB_MODE = "@@layout/CHANGE_CLOUD_TAB_MODE"
}
export interface LayoutState {
  layoutMode:
    | LAYOUT_MODES.LIGHT
    | LAYOUT_MODES.DARK,
  activeTab:
    | TABS.BOOKMARK
    | TABS.MANAGE
    | TABS.CHAT
    | TABS.CONTACTS
    | TABS.SETTINGS
  | TABS.USERS,
  cloudTab:
     | CLOUD_TABS.IMPORT
  | CLOUD_TABS.TEMPLATE,
  tabOpen:false
  

}
