import React, { useEffect, useState } from 'react'
import Table from '../../../components/Table';
import {
  Form,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  Input,
} from "reactstrap";


interface TableDataTypes{
  template: any;
}
const TableData = ({ template }: TableDataTypes) => {
  let rowsPerPageOptions = [5, 10, 25];
  const [page, setPage] = useState(0);

  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[0]);

  return (
    <div>

        <table>
        <thead>
          <tr>
            <th>Name</th>
            <th>status</th>
            <th>catagory</th>
          </tr>
        </thead>
        <tbody>
          {template.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row:any) => (
            <tr key={row.id}>
              <td>{row.name}</td>
              <td>{row.status}</td>
               <td>{row.category}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}



interface GroupItemProps {
  audience: any;
   onSendAudience: (value: any) => void;
}
interface DataTypes {
  name: string | null;
}
const Group = ({ audience, onSendAudience }: GroupItemProps) => {
  const [Audience, SetAudience] = useState([]);
  const [isCreating, SetisCreating] = useState(false);
  useEffect(() => {
    if (audience) {
      SetAudience(audience);
    }
  },[audience])
  const [data, setData] = useState<DataTypes>({
    name: null,
  });
  const onChangeData = (field: "name", value: string) => {
    let modifiedData: DataTypes = { ...data };
    if (value === "") {
      modifiedData[field] = null;
    } else {
      modifiedData[field] = value;
    }
    setData(modifiedData);
  };
  return (
    <div className='cloud_container'>
      <div className="cloud_middle">
           <h4>Group</h4>
        {isCreating ? <>
          <div className='d-flex align-items-center'>
            <div onClick={e => SetisCreating(false)}><i className='bx bx-arrow-back'></i></div>
            <span className='mx-2'>Back</span>
          </div>
          <div className='content'>
               <Form>
      
                    <div className="mb-3">
                      <Label htmlFor="AddContactModalname-input" className="form-label">
                        Name
                      </Label>
                      <Input
                        type="text"
                        className="form-control"
                        id="AddContactModalname-input"
                        placeholder="Enter Name"
                        value={data["name"] || ""}
                        onChange={(e: any) => {
                          onChangeData("name", e.target.value);
                        }}
                      />
                    </div>
          
                    <Button
                      type="button"
                      color="primary"
                      // disabled={!valid}
                      onClick={() => onSendAudience(data.name)}
                    >
                      Invite
                    </Button>
              </Form>
          </div>
          
          </>
          :
          <>
            <div className="d-flex justify-content-end py-2">
              <button className="btn btn-primary" onClick={e=>SetisCreating(true)}>create</button>
            </div>
            <div className='content'>
                <TableData template={Audience} />
            </div>
            
            </>
        }
     
        
      </div>
    </div>
  )
}

export default Group
