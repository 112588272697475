import React, { useState } from 'react';
import Select, { components, SingleValue } from 'react-select';
import {
  Input
} from "reactstrap";
import { createSelector } from "reselect";
import { getGallary } from '../../../redux/actions';
import GallaryModal from "../../../components/GallaryModal";
import sampleImage from "../../../assets/images/icons/gallary.png";
import AsyncSelect from 'react-select/async';
import makeAnimated from 'react-select/animated';
import * as XLSX from "xlsx";
import TemplateComponents from "./TemplateComponents/Index";
import Dropzone from "react-dropzone";
import ProgressBar from "react-progressbar";
import { useRedux } from "../../../hooks/index";
import { te } from 'date-fns/locale';
import { getContactByAudId } from '../../../redux/actions';
interface ContactItemProps {
  contactDetail: any;
  templateDetails: any;
  audienceDetail: any;
  onSingleDataImport: (value: any) => void;
  onBulkTemplateImport:(value: any) => void;
}
interface SelectVarOptions {
  value: string;
  index: number;
  default: string;
  isString:Boolean
}
interface VariableOptions {
  value: string;
  label: string;
}
interface OptionType{
  label: string;
  language: string;
  value: string | number;
  components: any;
  varialeLength: number;
  hasImage: boolean;
  variables: any;
  show_image: any;
}
interface Record {
  contact: any,
  template: any,
}
const Import = ({ contactDetail, templateDetails, audienceDetail, onSingleDataImport, onBulkTemplateImport }: ContactItemProps) => {
  const { dispatch, useAppSelector } = useRedux();


 const errorData = createSelector(
    (state: any) => state.Contacts,
    (state) => ({
      contactsAudList: state.audience_contacts,
    })
    );

  // Inside your component
    const { contactsAudList } = useAppSelector(errorData);

  const stringsvalue = {variable_select:"{...}"}

  const [mediaUrl, SetMediaUrl] = useState({
    url: "",
    is_insertable:false
  });
  const [sampleImg, SetSampleImage] = useState(sampleImage); 
  const [uploading, setUploading] = useState(false);
  const [progress, setProgress] = useState(0);
    const predefinedFireable = {
    isfirable: false,
    isfired: false,
    data: {},
    dataCount: 0,
    totalFirable: 0,
    totalRows: [] as any[]
  };
  const [Firable, SetFireable] = useState(predefinedFireable);
  // template variables
   const [selectedVariable, setSelectedVariable] = useState<SelectVarOptions[]>([]);
  
  const variableOptions: VariableOptions[] = [{
    label: 'name',
    value: '$name'
  }, {
    label: 'number',
    value: '$number'
    }];


  const handleVariableChange = (e:any, index:number) => {
      setSelectedVariable(prevState => {
          const objIndex = prevState.findIndex(item => item.index === index);
          if (objIndex !== -1) {
            const newSet = [...prevState];
            newSet[objIndex] = { ...newSet[objIndex], value: e };
            return newSet;
          }
          return prevState;
        });
  };
  // template variables end
  const [templateValue , settemplateValue] = useState<OptionType>();
  const [audienceValue, setaudienceValue] = useState<OptionType>();
  const [sheetHeader, SetSheetHeader] = useState<any>();
  const idsAndNames = contactDetail.map((data: any, key: number) => ({ id: data.id, value: data.name, label: data.name }));
  const templateOptions = templateDetails.map((data: any, key: number) => ({ value: data.name, label: data.name, components:data.components, language:data.language }));

  const audienceOptions = audienceDetail.map((data: any, key: number) => ({ value: data.id, label: data.name }));
const idsAndPhoneNumbers = contactDetail.map((data:any, key:number) => ({ id:data.id, value: data.wa_id, label: data.wa_id }));
  const [inputs, setInputs] = useState([{ name: '', mobile: '' }]);
  
  const [recordCollection, SetRecordCollection] = useState<Record[]>([{ contact: "", template: "" }]);

const [selectedOption, setSelectedOption] = useState(null);
const optionsArray = [
  { value: 'chocolate', label: 'Chocolate' },
  { value: 'strawberry', label: 'Strawberry' },
  { value: 'vanilla', label: 'Vanilla' },
  { id:2, value: 'himanshu', label:"Himanshu"}
  ];
  const handleSelectedOption = (data: any, type: string, index: number) => {
    
    let array;
    if (type == "template") {
        array = {template:data.value}
    } else {
      array =  contactDetail.filter((option:any) => option[type] === data.value);
      array = array.length > 0 ? array[0] : null;
    }

    // SetRecordCollection([...recordCollection, array]);
     SetRecordCollection(prevState => {
        const newRecordCollection = [...prevState]; // Create a copy of the previous state
        if (index >= 0 && index < newRecordCollection.length) {
          // If the index exists, modify the array at that index
          if (type =="template") {
            newRecordCollection[index].template =  array ;
          } else {
            newRecordCollection[index].contact =  array ;
          }
             
        } else {
            // If the index doesn't exist, create a new array at that index
            for (let i = newRecordCollection.length; i <= index; i++) {
                // newRecordCollection.push([]); // Create empty arrays until the desired index
          }
          if (!recordCollection[index]) {
              recordCollection[index] = { contact: "", template:null }; // Initialize with a default value
          }
          if (type =="template") {
            newRecordCollection[index].template =  array ;
          } else {
            newRecordCollection[index].contact =  array ;
          }
        }
        return newRecordCollection; // Return the modified state
     });
    
  }
  const show_liabrary = () => {
     setIsOpen(true);
  }
  const check_if_record_exist = (field:string, index: number) => {
    let array = recordCollection;
    if (array.length > index) {
      let val;
      if (field == "name") {
        val = array[index].contact?.name;
      } else if(field == "wa_id"){
        val = array[index].contact?.wa_id;
      } else {
         val = array[index].template?.template;
      }
      if (val) {
        return {value:val, label:val} 
      } else {
        return "";
      }
      
    } else {
      return "";
    }
  }

  const handleInputChange = (index:any, fieldName:any , event:any) => {
    const newInputs = [...inputs];
    newInputs[index] = {
      ...newInputs[index],
      [fieldName]: event.target.value
    };
    setInputs(newInputs);
  };

  const addInput = () => {
    SetRecordCollection([...recordCollection, { contact: "", template: "" }]); // Add a new empty input
  };
  const removeInput = (index:number) => {
  const newArray = [...recordCollection];
    newArray.splice(index, 1);
    SetRecordCollection(newArray); // Update state to trigger re-render
  }
  const sendTemplate = () => {
    let data = JSON.stringify(recordCollection)
    onSingleDataImport(data);
  }
  
  const importToServer = async () => {
    if (mediaUrl.is_insertable && mediaUrl.url == "") {
        return;
      }  
  
      let totalfireable = 0;
      const promises = contactsAudList.map(async (item:any, index:number) => {
        if (templateValue && audienceValue) {
            let template_detail:any = {
                messaging_product: "whatsapp",
                to: item.wa_id,
                type: "template",
                template: {
                  name: templateValue?.value,
                  language: {
                    code: templateValue?.language,
                  },
                },
              }   
          let components:any = [];
            templateValue.components.map((tempitem: any, i: number) => {
            if (tempitem.type === 'HEADER') {
              if (tempitem.format == 'IMAGE') {
                let detail = {
                  type: "header",
                  parameters: [
                    {
                      type: "image",
                      image: {
                        link:mediaUrl.url,
                      },
                    },
                  ],
                };
                components.push(detail);
              }
              }
            });
          if (selectedVariable.length>0) {
              let var_draft:any ={
                  type: "body",
                  parameters: []
                }
            selectedVariable.map((selectitem: any, i: number) => {
              let variable = "";

              console.log("test", selectitem, item, selectitem.isString);
              
                if (selectitem.isString ) {
                  variable = selectitem.value;
                } else {
                  if (selectitem.value == "name") {
                      variable = item.name?item.name:selectitem.default;
                  } else if(selectitem.value == "number"){
                      variable = item.wa_id?item.wa_id:selectitem.default;
                  }
                  
                }
                var_draft.parameters.push({type:"text",text:variable});
              });
            components.push(var_draft);
            }
          
          if (components.length>0) {
            template_detail.template['components'] = components;
          }
          
          
        let detail = {
          detail:JSON.stringify(item),
          template: templateValue?.value,
          audience: audienceValue.value,
          variables: JSON.stringify(selectedVariable),
          template_detail: JSON.stringify(template_detail)
          };
          console.log("temp detail", templateValue, template_detail);
          // return;
        try {
          await new Promise((resolve) => setTimeout(resolve, 100));
          const response =  onBulkTemplateImport(detail);
          totalfireable++;
        } catch (error) {
        }
      }
    });

    await Promise.all(promises);
     
     
    SetFireable({ ...Firable, isfired: true, totalFirable: totalfireable });
  };
  const handleTemplateChange = (event: any) => {
    let array = event;
    setSelectedVariable([]);
     event.components.map((item:any, i:number) => {
      if (item.type == "BODY") {
        if (item.example) {
          let length = item.example.body_text[0].length;
          let values = Array.from({ length }, (_, index) => (
                { value: "",index:index,default: "",isString:false }   
          ));
          setSelectedVariable(values) 
            array = {...event, varialeLength:length}
        }
       }
       
       if (item.type == "HEADER") {
         if (item.format == "IMAGE") {
           SetMediaUrl({url: "",is_insertable:true});
         } else {
           SetMediaUrl({url: "",is_insertable:false});
         }
       }
     });
    array.show_image = sampleImg; 
    console.log("template value", array);
    settemplateValue(array);
  };
  const handleChange = (event: any) => {
    dispatch(getContactByAudId(event.value));
    setaudienceValue( event);
  };
  const open_fallback = (e: any, selector: string) => {
    const variableIcon = e.target.parentNode.querySelector(selector); 
    console.log("variableIcon",e.target.parentNode,selector, variableIcon);
    if (variableIcon) {
      if (variableIcon.classList.contains('active')) {
        variableIcon.classList.remove('active'); 
      } else {
        variableIcon.classList.add('active'); 
      }
    }
  }
const open_options = (e:any, selector:string) => {
  const variableIcon = e.target.closest(selector); 
  if (variableIcon) {
    if (variableIcon.classList.contains('active')) {
      variableIcon.classList.remove('active'); 
    } else {
      variableIcon.classList.add('active'); 
    }
  }
};
  
  const setDefaultVar = (e:string, index:number, type:string)=>{
    setSelectedVariable(prevState => {
          const objIndex = prevState.findIndex(item => item.index === index);
          if (objIndex !== -1) {
            const newSet = [...prevState];
            if (type == 'default') {
              newSet[objIndex] = { ...newSet[objIndex], default: e };
            } else {
              newSet[objIndex] = { ...newSet[objIndex], value: e, isString:true };
            }
            
            return newSet;
          }
          return prevState;
    });

    console.log("check default var", selectedVariable);
  }
  // media url
  const [isOpen, setIsOpen] = useState<boolean>(false);
  
  const getMediaUrl = (data:any) => {
    console.log("media url",data);
  }
  const closeModal = () => {
    setIsOpen(false);
  };
  const setMediaUrlFunc = (url: any) => {
    if (templateValue !==undefined) {
       templateValue.show_image = url; 
       console.log("changed template value", templateValue);
       settemplateValue(templateValue);
    }
     SetMediaUrl({...mediaUrl,url:url})
   }
  return (
    <>
      <GallaryModal
        isOpen={isOpen}
        onClose={closeModal}
        getData={setMediaUrlFunc}
      />
       <div className='cloud_container'>
        <div className="cloud_middle">
          <h4>Import</h4>
          <div className="content">
                {recordCollection.map((input:any, index:number) => (
        <div className='' key={index}>
          <div className='row'>
            <div className='col-md-3'>
              <Select
                value={check_if_record_exist("name", index)}
                onChange={(e: any) => {
                    handleSelectedOption(e, "name", index)
                    }}
                options={idsAndNames}
                placeholder="Name"
            />
          </div>
          <div className='col-md-3'>
             <Select
                value={check_if_record_exist("wa_id", index)}
                onChange={(e: any) => {
                    handleSelectedOption(e, "wa_id", index)
                    }}
                options={idsAndPhoneNumbers}
                placeholder="Number"
            />
          </div>
          <div className='col-md-3'>
              <Select
                defaultValue={""}
                value={check_if_record_exist("template", index)}
                onChange={(e: any) => {
                    handleSelectedOption(e, "template", index)
                    }}
                options={templateOptions}
                placeholder="Template"
            />
          </div>
          <div className='col-md-3'>
              {(index == (recordCollection.length - 1)) ?
                <div onClick={addInput} className='add_icon'>
                  <i className="bx bxs-plus-circle"></i>
                </div>
                : <div onClick={e=>removeInput(index)} className='add_icon'>
                  <i className="bx bx-minus-circle"></i>
                </div>
              }
         </div>
        </div>
        </div>
      ))}
      
        
     <button className=' my-2 btn btn-sm px-3 btn-soft-primary font-size-18 header-item waves-effect' onClick={sendTemplate}>Send</button>
      <div className='row'>
      <div className='col-md-6'>
        <div className='row'>
           <div className='col-md-12'>
              <Select
        
                value={templateValue}
                onChange={e=>handleTemplateChange(e)}
                options={templateOptions}
                placeholder="Template"
            />
          </div>
           <div className='col-md-12'>
              <Select
       
                value={audienceValue}
                onChange={e=>handleChange(e)}
                options={audienceOptions}
                placeholder="Audience"
            />
            </div>
            
            {templateValue?.varialeLength ? <div>
              <h5>Parameters</h5>
              <p>Your can personalize messages with - $FirstName, $Name, $MobileNumber, $LastName Your can also use your custom attributes - $my_custom_attribute_name.</p>
              
                   
                    {selectedVariable.map((item: any, index: number) => {
                      return <div key={index} className=' perameter_input template_variable_set'>
                        
                        <div className='d-flex align-items-center'>
                          <div>
                            {
                              !item.isString ?
                              item.default ?
                                  <div onClick={e => open_fallback(e, '.fill_default_value')} className='has-default'>
                                     {item.value}
                                   </div>
                                  :
                                  item.value ?
                                    <>
                                    <div onClick={e=>open_fallback(e,'.fill_default_value')} className='no-default'>
                                      <i className="bx bxs-error"></i>
                                      {item.value}
                                    </div>
                                    </>
                                    : null
                                    : null
                                }
                               <div className='fill_default_value'>
                                  <span>Fallback:fill Default value in case of any network issue</span>
                                  <Input
                                      type="text"
                                      className=""
                                      value={item.default}
                                      onChange={e=>setDefaultVar(e.target.value, index,'default')}
                                    />
                              </div>
                          </div>
                          
                        <div className='input_catainer'>
                          <Input
                          type="text"
                          value={item.isString?item.value:""}
                          onChange={e=>setDefaultVar(e.target.value, index, "value")}
                        />

                        </div>
                        </div>
                        <div key={index} className='variable_icon' onClick={e=>open_options(e,'.variable_icon')}>
                          {stringsvalue.variable_select}
                          <div className='variable_options '>
                            {variableOptions.map((item:any, i:number) => {
                              return <div key={i} className='' onClick={e=>handleVariableChange(item.label, index)}>{item.value}</div>
                            })}
                          </div>
                        </div>
                      </div>
                    })}
                  
            </div>:null

            }
                </div>
                {mediaUrl.is_insertable ?
                  <div>
                    <h5> Media </h5>  
                    <p>size {"<"} 5MB, Accepted formats - .png or .jpeg</p> 
                      <div>
                        <Input
                            type="text"
                            className=""
                            value={mediaUrl.url}
                            onChange={e=>setMediaUrlFunc(e.target.value)}
                              />
                      
                    </div>
                     <div className='row mt-2'>
                    <div className="col-md-2">
                          or
                    </div>
                    <div className="col-md-8" >
                          <div className='upload-btn' onClick={show_liabrary}>Upload from Media Liabrary</div>
                    </div>
                  </div> 
                  </div>
                : null
                }
           <div className=''>
           
                 
                      
                 
           <div>
                    
        </div>
       {Firable.isfired ? (
                <div>{Firable.totalFirable} Record Sent</div>
              ) : 
              null
                }
                  <div className='mt-2'>
                    <button
                     className='btn btn-sm px-3 btn-soft-primary font-size-18 header-item waves-effect'
                    onClick={importToServer}
                    color="secondary"
                  >
                    Fire Message
                  </button>
                </div>
        </div>
    
      </div>
            <div className='col-md-6'>
              <div className=''>
                <TemplateComponents
                  templateValue={templateValue}
                />
              </div>
       
          </div>
          </div>
          </div>    
        </div>
      </div>
    </>
  );
}

export default Import;

