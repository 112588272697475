import { TemplatesActionTypes } from "./types";
// common success
export const templatesApiResponseSuccess = (actionType: string, data: any) => ({
  type: TemplatesActionTypes.API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});
// common error
export const templatesApiResponseError = (
  actionType: string,
  error: string
) => ({
  type: TemplatesActionTypes.API_RESPONSE_ERROR,
  payload: { actionType, error },
});
export const getTemplates = (filters?: object) => ({
  type: TemplatesActionTypes.GET_TEMPLATES,
  payload: filters,
});


export const sendTemplates = ( value: any) => ({
  type: TemplatesActionTypes.SEND_TEMPLATES,
  payload:  value ,
});
export const createTemplates = ( value: any) => ({
  type: TemplatesActionTypes.CREATE_TEMPLATES,
  payload:  value ,
});
export const bulkTemplates = ( value: any) => ({
  type: TemplatesActionTypes.BULK_TEMPLATES,
  payload: value,
});