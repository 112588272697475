import React, { useEffect, useState } from "react";
import config from "../config";
import Loader from "./Loader";
import { APIClient } from "../api/apiCore";
import {
  Form,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  Input,
} from "reactstrap";
import { createSelector } from "reselect";
import { getGallary } from "../redux/actions";
import { useRedux } from "../hooks";
interface GallaryModalProps {
  isOpen: boolean;
  onClose: () => void;
  getData: (data: any) => void;
}
const GallaryModal = ({
  isOpen,
  onClose,
  getData,
}: GallaryModalProps) => {

    const { dispatch, useAppSelector } = useRedux();
    const api = new APIClient();

  const errorData = createSelector(
    (state : any) => state.Gallary,
    (state) => ({
    gallaryData: state.gallary,
    getGallaryLoading: state.getGallaryLoading,
    isGallaryFetched: state.isGallaryFetched,
    })
  );
  
  // Inside your component
  const { gallaryData,getGallaryLoading, isGallaryFetched } = useAppSelector(errorData);

  useEffect(() => {
    if (isOpen) {
       dispatch(getGallary());
    } 
  },[isOpen]);
  const [mediaUrl, SetMediaUrl] = useState("");
  const uploadMedia = async(e:any) => {
     let formData = new FormData();
     formData.append('file', e.target.files[0]);
    let res = await api.uploadFile("/api/upload", formData);
    if (res.data) {
      getData(config.API_URL + res.data.url);
      onClose();
    }
    console.log("response", res.data.url);
  }

  const imageSet = (url:any) => {
      getData(url);
      onClose();
  }
  // pagination data
  
  // State variables
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 6;

  // Logic to calculate which items to display
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = gallaryData.slice(indexOfFirstItem, indexOfLastItem);
  const paginate = (pageNumber:any) => setCurrentPage(pageNumber);

  return (
    <Modal isOpen={isOpen} toggle={onClose} tabIndex={-1} centered scrollable >
      <ModalHeader  toggle={onClose} >
      <div className="modal-title-custom text-white font-size-16 ">
          <Button className="btn btn-primary w-lg btn btn-secondary">
            <label htmlFor="">Upload Media</label>
            <input type="file" onChange={uploadMedia} />
          </Button> 
      </div>
      </ModalHeader>
      <ModalBody className="p-4">
        {getGallaryLoading && !isGallaryFetched && <Loader />}
        <div className="row">
            {currentItems.map((item:any, i:number) => (
            <div key={i} className="col-md-4">
              <div className="image-wrapper" onClick={e=>imageSet(config.API_URL + item.url)}>
                <img height={40} src={config.API_URL + item.url} alt="" />
              </div>
            </div>
          ))}
            {/* Pagination buttons */}
          <div className="pagination">
            {Array.from({ length: Math.ceil(gallaryData.length / itemsPerPage) }, (_, index) => (
              <button key={index} onClick={() => paginate(index + 1)}>{index + 1}</button>
            ))}
          </div>
        </div>
         
      </ModalBody>

    </Modal>
  );
};

export default GallaryModal;
