import React, { useState } from 'react';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import TemplateComponents from "./TemplateComponents/Index";
import Create from './TemplateComponents/Create';
interface Data {
  id: number;
  name: string;
  status: string;
  components: any;
  category: string;
}
interface TableDataTypes{
  template: any;
  SetShowComponent: (value: any) => void;
}
const TableData = ({ template,SetShowComponent }: TableDataTypes) => {
  let rowsPerPageOptions = [5, 10, 25];
  const [page, setPage] = useState(0);

  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[0]);

  const handleChangePage = (newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const show_component = (components: any) => {
    SetShowComponent({ status: true, components: components });
  }
  return (
    <div>

        <table>
        <thead>
          <tr>
            <th>Name</th>
            <th>status</th>
            <th>catagory</th>
          </tr>
        </thead>
        <tbody>
          {template.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row:any) => (
            <tr key={row.id} onClick={e=>show_component(row.components)}>
              <td>{row.name}</td>
              <td>{row.status}</td>
               <td>{row.category}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}



interface TemplateProps{
  templateDetails: any;
}

const Template = ({templateDetails}:TemplateProps) => {
    const [activeTab, setActiveTab] = useState<number>(0);
   const [showComponent, SetShowComponent] = useState({
    status: false,
    components: []
   });
  let approved_template = templateDetails.filter((e: any) => { return e.status == "APPROVED" });
  let pending_template = templateDetails.filter((e: any) => { return e.status == "PENDING" });
  let rejected_template = templateDetails.filter((e: any) => { return e.status == "REJECTED" });
  const [isCreating, SetisCreating] = useState(false);
  const onBack = () => {
     SetisCreating(false)
   }
  const onClose = () => {
    SetShowComponent({ status: false, components: [] });
  }
  const handleTabClick = (index:number) => {
    setActiveTab(index);
  };
  const menuList = ["All", "Approved", "Pending", "Rejected"]
  return (
    <div className='cloud_container'>
      <div className="cloud_middle">
        <h4>Template</h4>
        {isCreating ?
          <Create
           onBack={onBack}
          />
        :
          <>
            <div className="d-flex justify-content-end py-2">
            <button className="btn btn-primary" onClick={e=>SetisCreating(true)}>create</button>
          </div>
        <Modal
          isOpen={showComponent.status}
          toggle={onClose}
          tabIndex={-1}
          centered
          scrollable
          color="white"
          id="addgroup-exampleModal"
          role="dialog">
          {/* <ModalHeader className="modal-title-custom bg-primary " toggle={onClose} >
          Create New Group
        </ModalHeader> */}
        <ModalHeader toggle={onClose} className="bg-primary">
          <div className="modal-title modal-title-custom text-white bg-primary font-size-16">
          Create New Group
          </div>
        </ModalHeader>
          {/* <div className="modal-title text-white font-size-16 bg-primary text-white">
            creact new grop
          </div> */}

          <ModalBody className="p-4">
                <TemplateComponents
                  templateValue={showComponent}
                />
          </ModalBody>
          <ModalFooter>
            <Button color="link" type="button" onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </Modal>
        <div className='content'>
             <div className="tabs">
              <div className="tab-list d-flex justify-content-between">
                {menuList.map((item:any, i:number) => {
                  return <div key={i} className={`tab ${activeTab === i ? 'active' : ''}`}  onClick={() => handleTabClick(i)} >
                    {item}
                  </div>
                })

                }
              </div>
              <div className="tab-content">
                {activeTab === 0 &&
                  <div>
                    <TableData
                      template={templateDetails}
                      SetShowComponent={SetShowComponent}
                    />
                  </div>}
                {activeTab === 1 &&
                  <div>
                  <TableData
                      template={approved_template}
                      SetShowComponent={SetShowComponent}
                    />
                </div>}
                {activeTab === 2 &&
                  <div>
                  <TableData
                      template={pending_template}
                      SetShowComponent={SetShowComponent}
                    />
                  </div>}
                {activeTab === 3 &&
                  <div>
                  <TableData
                      template={rejected_template}
                      SetShowComponent={SetShowComponent}
                    />
                </div>}
              </div>
            </div>
        </div>
          
          </>
        }
    
         
        </div>
    </div>
  )
}

export default Template
