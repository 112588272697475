import React, { createContext, useState } from "react";
// import "./myStyles.css";
// import Sidebar from "./Sidebar";
import { Outlet } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

export const myContext = createContext<{
  refresh: boolean;
  setRefresh: React.Dispatch<React.SetStateAction<boolean>>;
}>({
  refresh: true,
  setRefresh: () => {},
});

function MainContainer() {
  const dispatch = useDispatch();
  const lightTheme = useSelector((state: { themeKey: boolean }) => state.themeKey);
  const [refresh, setRefresh] = useState(true);

  return (
    <div className={"main-container" + (lightTheme ? "" : " dark")}>
      <myContext.Provider value={{ refresh: refresh, setRefresh: setRefresh }}>
        {/* <Sidebar /> */}
        <Outlet />
      </myContext.Provider>
    </div>
  );
}

export default MainContainer;
