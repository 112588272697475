import React, { useEffect, useState } from "react";
import { Button, Col, Row } from "reactstrap";
import { ContactTypes } from "../../../data";
import Import from "./Import";
import Template from "./Template";
import Group from "./Group";
import { useRedux } from "../../../hooks";
import { TABS, CLOUD_TABS } from "../../../constants";
import { changeTab , changeCloudTab} from "../../../redux/actions";
import { createSelector } from "reselect";
import { sendTemplates, bulkTemplates, sendAudience } from "../../../redux/actions";
import { TabContent, TabPane } from "reactstrap";
import {
  Nav,
  NavItem,
  Dropdown,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
  NavLink,
  UncontrolledTooltip,
} from "reactstrap";
import { getTemplates } from "../../../redux/actions";

interface CloudContentProps {
  contacts: any;
  templates: any;
  onSingleDataImport: (value: any) => void;
  onBulkTemplateImport:(value: any) => void;
}
const CloudContentTab = ({ contacts, templates, onSingleDataImport, onBulkTemplateImport }: CloudContentProps) => {
  // global store
 const { dispatch, useAppSelector } = useRedux();
  // const { activeTab } = useAppSelector(state => ({
  //   activeTab: state.Layout.activeTab,
  // }));
  const errorData = createSelector(
    (state: any) => state.Layout,
    (props : any) => props.Audience,
    (state, props) => ({
      activeTab: state.activeTab,
      cloudTab: state.cloudTab,
      audience:props.audience,
    })
  );
  // Inside your component
  const { activeTab, cloudTab, audience } = useAppSelector(errorData);
  const sendAudienceFunc = (data:any) => {
    dispatch(sendAudience(data))
  }
  return (
    <>
    
      {/* start chat-leftsidebar */}
      <div className="">
        <TabContent activeTab={cloudTab}>
          {/* Start Profile tab-pane */}
          <TabPane
            tabId={CLOUD_TABS.IMPORT}
            role="tabpanel"
          >
           <Import contactDetail={contacts} templateDetails={templates} audienceDetail={audience} onSingleDataImport={onSingleDataImport} onBulkTemplateImport={onBulkTemplateImport} />
          </TabPane>

          <TabPane
            tabId={CLOUD_TABS.TEMPLATE}
            role="tabpanel"
          >
          <Template templateDetails={templates} />
          </TabPane>

          <TabPane
            tabId={CLOUD_TABS.GROUP}
            role="tabpanel"
          >
            <Group
              audience={audience}
              onSendAudience={sendAudienceFunc}
            />
          </TabPane>

       
        </TabContent>
      </div>
    </>
  );
};






const Index = () => {
  const { dispatch, useAppSelector } = useRedux();
   const errorData = createSelector(
    (state : any) => state.Contacts,
    (state) => ({
      contactsList: state.contacts,
    })
  );
    let tempErrorData = createSelector(
      (state: any) => state.Templates,
    (state) => ({
      templateList: state.templates,
    })
  );
  // Inside your component
  const { contactsList } = useAppSelector(errorData);
  const {  templateList } = useAppSelector(tempErrorData);
  const [contacts, setContacts] = useState<Array<any>>([]);
   const [templates, setTemplates] = useState<Array<any>>([]);
  useEffect(() => {
    if (contactsList?.length > 0 && templateList?.length > 0 ) {
      setContacts(contactsList);
      setTemplates(templateList);
    }
  }, [contactsList, templateList]);

  useEffect(() => {
    dispatch( getTemplates());
  }, [dispatch]);


  
  const SingleDataImport = ( value: any) => {
    console.log("single Data Import", value);
    dispatch(sendTemplates(value));
  };
  const BulkTemplateImport = (value: any) => {
    console.log("bulk Template", value);
    dispatch(bulkTemplates(value));
  }
  return (
    <React.Fragment>
      <div className="">
        <Row className="w-100 justify-content-center">
          <Col xxl={5} className="col-md-95">
            <CloudContentTab  contacts={contacts} templates={templates} onSingleDataImport={SingleDataImport} onBulkTemplateImport={BulkTemplateImport} />
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};

export default Index;
