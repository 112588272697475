export enum AudienceActionTypes {
  API_RESPONSE_SUCCESS = "@@Audience/API_RESPONSE_SUCCESS",
  API_RESPONSE_ERROR = "@@Audience/API_RESPONSE_ERROR",

  GET_AUDIENCE = "@@Audience/GET_AUDIENCE",
  SEND_AUDIENCE = "@@Audience/SEND_AUDIENCE",
}
export interface AudienceState {
  audience: Array<any>;
}
