import { APIClient } from "./apiCore";
import * as url from "./urls";

const api = new APIClient();

const getApiSettings = () => {
  return api.get(url.GET_API_SETTINGS);
};
const apiUpdateSettings = (field: string, value: any) => {
  return api.create(url.API_UPDATE_SETTINGS, {
    name: field,
    value: value,
  });
};

export { getApiSettings, apiUpdateSettings };
