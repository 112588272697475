

export interface CloudLangType {
  language: string;
  code: string;
}

const cloud_lang: CloudLangType[] = [
    {
        "language": "Afrikaans",
        "code": "af"
    },
    {
        "language": "Albanian",
        "code": "sq"
    },
    {
        "language": "Arabic",
        "code": "ar"
    },
    {
        "language": "Azerbaijani",
        "code": "az"
    },
    {
        "language": "Bengali",
        "code": "bn"
    },
    {
        "language": "Bulgarian",
        "code": "bg"
    },
    {
        "language": "Catalan",
        "code": "ca"
    },
    {
        "language": "Chinese (CHN)",
        "code": "zh_CN"
    },
    {
        "language": "Chinese (HKG)",
        "code": "zh_HK"
    },
    {
        "language": "Chinese (TAI)",
        "code": "zh_TW"
    },
    {
        "language": "Croatian",
        "code": "hr"
    },
    {
        "language": "Czech",
        "code": "cs"
    },
    {
        "language": "Danish",
        "code": "da"
    },
    {
        "language": "Dutch",
        "code": "nl"
    },
    {
        "language": "English",
        "code": "en"
    },
    {
        "language": "English (UK)",
        "code": "en_GB"
    },
    {
        "language": "English (US)",
        "code": "en_US"
    },
    {
        "language": "Estonian",
        "code": "et"
    },
    {
        "language": "Filipino",
        "code": "fil"
    },
    {
        "language": "Finnish",
        "code": "fi"
    },
    {
        "language": "French",
        "code": "fr"
    },
    {
        "language": "Georgian",
        "code": "ka"
    },
    {
        "language": "German",
        "code": "de"
    },
    {
        "language": "Greek",
        "code": "el"
    },
    {
        "language": "Gujarati",
        "code": "gu"
    },
    {
        "language": "Hausa",
        "code": "ha"
    },
    {
        "language": "Hebrew",
        "code": "he"
    },
    {
        "language": "Hindi",
        "code": "hi"
    },
    {
        "language": "Hungarian",
        "code": "hu"
    },
    {
        "language": "Indonesian",
        "code": "id"
    },
    {
        "language": "Irish",
        "code": "ga"
    },
    {
        "language": "Italian",
        "code": "it"
    },
    {
        "language": "Japanese",
        "code": "ja"
    },
    {
        "language": "Kannada",
        "code": "kn"
    },
    {
        "language": "Kazakh",
        "code": "kk"
    },
    {
        "language": "Kinyarwanda",
        "code": "rw_RW"
    },
    {
        "language": "Korean",
        "code": "ko"
    },
    {
        "language": "Kyrgyz (Kyrgyzstan)",
        "code": "ky_KG"
    },
    {
        "language": "Lao",
        "code": "lo"
    },
    {
        "language": "Latvian",
        "code": "lv"
    },
    {
        "language": "Lithuanian",
        "code": "lt"
    },
    {
        "language": "Macedonian",
        "code": "mk"
    },
    {
        "language": "Malay",
        "code": "ms"
    },
    {
        "language": "Malayalam",
        "code": "ml"
    },
    {
        "language": "Marathi",
        "code": "mr"
    },
    {
        "language": "Norwegian",
        "code": "nb"
    },
    {
        "language": "Persian",
        "code": "fa"
    },
    {
        "language": "Polish",
        "code": "pl"
    },
    {
        "language": "Portuguese (BR)",
        "code": "pt_BR"
    },
    {
        "language": "Portuguese (POR)",
        "code": "pt_PT"
    },
    {
        "language": "Punjabi",
        "code": "pa"
    },
    {
        "language": "Romanian",
        "code": "ro"
    },
    {
        "language": "Russian",
        "code": "ru"
    },
    {
        "language": "Serbian",
        "code": "sr"
    },
    {
        "language": "Slovak",
        "code": "sk"
    },
    {
        "language": "Slovenian",
        "code": "sl"
    },
    {
        "language": "Spanish",
        "code": "es"
    },
    {
        "language": "Spanish (ARG)",
        "code": "es_AR"
    },
    {
        "language": "Spanish (SPA)",
        "code": "es_ES"
    },
    {
        "language": "Spanish (MEX)",
        "code": "es_MX"
    },
    {
        "language": "Swahili",
        "code": "sw"
    },
    {
        "language": "Swedish",
        "code": "sv"
    },
    {
        "language": "Tamil",
        "code": "ta"
    },
    {
        "language": "Telugu",
        "code": "te"
    },
    {
        "language": "Thai",
        "code": "th"
    },
    {
        "language": "Turkish",
        "code": "tr"
    },
    {
        "language": "Ukrainian",
        "code": "uk"
    },
    {
        "language": "Urdu",
        "code": "ur"
    },
    {
        "language": "Uzbek",
        "code": "uz"
    },
    {
        "language": "Vietnamese",
        "code": "vi"
    },
    {
        "language": "Zulu",
        "code": "zu"
    }
];

export { cloud_lang };
