import React, { useState } from 'react';
import Select from 'react-select';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  Input,
  Collapse,
  Form,
} from "reactstrap";
import { useRedux } from "../../../../hooks/index";
import { inviteContact } from '../../../../redux/actions';
import * as XLSX from "xlsx";
import Dropzone from "react-dropzone";
import ProgressBar from "react-progressbar";
interface ImprotingDatatypes{
    isImporting: boolean
    audience:any
    SetisImporting: (value: any) => void;
}
const Importing = ({ isImporting,audience, SetisImporting }: ImprotingDatatypes) => {
    console.log(isImporting);
    const { dispatch, useAppSelector } = useRedux();
    const onclose = () => {
        SetisImporting(false);
    }
     const [sheetHeader, SetSheetHeader] = useState<any>();
    const [uploading, setUploading] = useState(false);
      const audienceCollection = audience.map((data: any, key: number) => ({ value: data.id, label: data.name }));
 
   const [audienceValue, setaudienceValue] = useState({label:"", value:""});
   const [progress, setProgress] = useState(0);
    const predefinedFireable = {
    isfirable: false,
    isfired: false,
    data: {},
    dataCount: 0,
    totalFirable: 0,
    totalRows: [] as any[]
  };
  const [Firable, SetFireable] = useState(predefinedFireable);
    const handleExcelUpload = (files:any) => {
    const file = files[0];
    const reader = new FileReader();

    reader.onload = async (e:any) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: "array" });

      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      let  rows: string[] = [];
       
      const  totalrowsValue = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
       totalrowsValue.forEach((item: any) => {
        if (item.length) {
          rows.push(item);
        }
      });
    
      // Simulating data processing with a timeout
      setUploading(true);
      const totalRows = rows.length;
    console.log("rows", rows, totalRows);
      const setFireableJson = {
        isfired: false,
        isfirable: true,
        data: {},
        totalFirable: 0,
        dataCount: totalRows,
        totalRows: rows,
      };
      SetSheetHeader(rows[0]);
      let processedRows = 0;
      for (let i = 0; i < totalRows; i++) {

        // Process each row here
        // Simulate processing time
        // migratecontacts(rows[i][0], rows[i][1]);
        // await new Promise((resolve) => setTimeout(resolve, 100));
        processedRows++;
        const progressPercentage = Math.round(
          (processedRows / totalRows) * 100
        );
        setProgress(progressPercentage);
      }
      SetFireable(setFireableJson);
      // Reset progress after completion
      setProgress(0);
      setUploading(false);
    };

    reader.readAsArrayBuffer(file);
  };
    const importToServer = async () => {
      let totalfireable = 0;
      const promises = Firable.totalRows.map(async (item, index) => {
        console.log("firable item", item);
       if (audienceValue) {
         let detail = {
          name: item[0],
          number: parseInt(item[1]),
          audience:audienceValue.value
        };

            try {
            await new Promise((resolve) => setTimeout(resolve, 100));
              const response =  onInviteContact(detail);
            totalfireable++;
            console.log("bulk template response",detail);
            } catch (error) {
            console.error("Error:", error);
            } 
            console.log("detail", detail);
        }
       
      
    
    });

    await Promise.all(promises);

    console.log("total firable", totalfireable);
    SetFireable({ ...Firable, isfired: true, totalFirable: totalfireable });
  };
  const onInviteContact = (data: any) => {
    dispatch(inviteContact(data));
  };
  return (
      <div>
        
         <Modal
      isOpen={isImporting}
      toggle={onclose}
      tabIndex={-1}
      centered
      scrollable
      color="white"
      id="addgroup-exampleModal"
      role="dialog">
     <ModalHeader toggle={onclose} className="bg-primary">
      <div className="modal-title modal-title-custom text-white bg-primary font-size-16">
      Create New Group
      </div>
     </ModalHeader>

              <ModalBody className="p-4">
                  <div>
                           <Select
                                value={audienceValue}
                                onChange={(e: any) => {
                                    setaudienceValue(e)
                                    }}
                                options={audienceCollection}
                                placeholder="Select Audience"
                            />
                      </div>
          <Dropzone
                onDrop={(acceptedFiles) => handleExcelUpload(acceptedFiles)}
              >
                {({ getRootProps, getInputProps }) => (
                  <div {...getRootProps()}>
                    <input {...getInputProps()} />

                    <button color="secondary">
                      Upload
                    </button>
                  </div>
                )}
                  </Dropzone>
                  {progress!=0?progress:null}
            {uploading && <ProgressBar completed={progress} />}
               {Firable.isfirable ? (
            <div>
              <div>{Firable.dataCount} Record Imported</div>
              {Firable.isfired ? (
                <div>{Firable.totalFirable} Record Sent</div>
              ) : (
                <div>
                  <button
                    onClick={importToServer}
                    color="secondary"
                  >
                    Fire Message
                  </button>
                </div>
              )}
            </div>
          ) : null}
      </ModalBody>
      <ModalFooter>
        <Button color="link" type="button" onClick={onclose}>
          Close
        </Button>
        <Button
          type="button"
          color="primary"
          onClick={onclose}
        >
          Import Contacts
        </Button>
      </ModalFooter>
    </Modal>
    </div>
  )
}

export default Importing
