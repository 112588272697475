import { APIClient } from "./apiCore";
import * as url from "./urls";

const api = new APIClient();

const getAudience = (filters?: object) => {
  return api.get(url.GET_AUDIENCE, filters);
};

const sendAudience = ( value: any) => {
  return api.create(url.SEND_TO_USER_AUDIENCE, {
    name: value,
  });
};

export { getAudience,sendAudience };
