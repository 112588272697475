import { APIClient } from "./apiCore";
import * as url from "./urls";

const api = new APIClient();

const getContacts = (filters?: object) => {
  return api.get(url.GET_CONTACTS, filters);
};

const inviteContact = (data: object) => {
  return api.create(url.GET_CONTACTS, data);
};
const getContactByAudId = (data: object) => {
    return api.create(url.GET_CONTACT_BY_AUD_ID, {
    audience_id: data,
  });
};
export { getContacts, inviteContact, getContactByAudId };
