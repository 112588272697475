// constants
import { TABS,CLOUD_TABS, LAYOUT_MODES } from "../../constants/index";

import { LayoutActionTypes, LayoutState } from "./types";

export const INIT_STATE: LayoutState = {
  activeTab: TABS.MANAGE,
  layoutMode: LAYOUT_MODES.LIGHT,
  cloudTab: CLOUD_TABS.IMPORT,
  tabOpen:false,
};

const Layout = (state = INIT_STATE, action: any) => {
  switch (action.type) {
    case LayoutActionTypes.CHANGE_TAB:
      return {
        ...state,
        activeTab: action.payload,
      };

    case LayoutActionTypes.CHANGE_LAYOUT_MODE:
      return {
        ...state,
        layoutMode: action.payload.layoutMode,
      };
    case LayoutActionTypes.CHANGE_CLOUD_TAB_MODE:
      return {
        ...state,
        cloudTab: action.payload.layoutMode,
        tabOpen: true
      };
    default:
      return { ...state };
  }
};

export default Layout;
